import { useState, useEffect } from 'react';
import axios from 'axios';
import update from 'immutability-helper';
import { useSelector } from 'react-redux';

const defaultTimetable = [
  { month: 'Sun', times: [] },
  { month: 'Mon', times: [] },
  { month: 'Tue', times: [] },
  { month: 'Wed', times: [] },
  { month: 'Thu', times: [] },
  { month: 'Fri', times: [] },
  { month: 'Sat', times: [] },
];

const useAdminUserTimetablePageState = (userId, history) => {
  const { token } = useSelector((state) => state);

  const [status, setStatus] = useState({ isLoading: false, isError: false, isSaving: false });
  const [timetable, setTimetable] = useState(defaultTimetable);
  const [userName, setUserName] = useState('');
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    setStatus({ isLoading: true, isError: false, isSaving: false });

    axios
      .get(`/api/v1/admin/users/${userId}/timetable`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUserName(response.data.userName);
        setTimetable(
          response.data.timetable.map((x) => ({
            ...x,
            times: x.times.map((y) => new Date(y)),
          })),
        );
        setStatus({ isLoading: false, isError: false, isSaving: false });
        setIsDataLoaded(true);
      })
      .catch(() => {
        setStatus({ isLoading: false, isError: true, isSaving: false });
      });
  }, []);

  const addTime = (index) => () => {
    setTimetable(update(timetable, { [index]: { times: { $push: [new Date()] } } }));
  };

  const deleteTime = (monthIndex, timeIndex) => () => {
    setTimetable(update(timetable, { [monthIndex]: { times: { $splice: [[timeIndex, 1]] } } }));
  };

  const changeTime = (monthIndex, timeIndex) => (value) => {
    setTimetable(update(timetable, { [monthIndex]: { times: { [timeIndex]: { $set: value } } } }));
  };

  const save = () => {
    setStatus({ isLoading: false, isError: false, isSaving: true });

    axios
      .put(
        `/api/v1/admin/users/${userId}/timetable`,
        { timetable },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(() => {
        setStatus({ isLoading: false, isError: false, isSaving: false });
        history.push('/admin/users');
      })
      .catch(() => {
        setStatus({ isLoading: false, isError: true, isSaving: false });
      });
  };

  return {
    userName,
    status,
    timetable,
    isDataLoaded,
    actions: {
      addTime,
      deleteTime,
      changeTime,
      save,
    },
  };
};

export default useAdminUserTimetablePageState;
