/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

import AdminPageContainer from '../common/AdminPageContainer';
import AdminPageTitle from '../common/AdminPageTitle';
import Loader from '../../common/loader/Loader';
import ErrorMessage from '../common/AdminErrorMessage';
import Input from '../common/DefaultInput';
import { SaveButton } from '../common/Buttons';

import useAdminEditWebinarPageState from './useAdminEditWebinarPageState';

import styles from './AdminEditWebinarPage.module.scss';

const AdminEditWebinarPage = ({ match, history }) => {
  const { id } = match.params;

  const { webinar, status, actions } = useAdminEditWebinarPageState(id, history);

  return (
    <AdminPageContainer>
      <AdminPageTitle to="/admin/webinars" title={`${id ? 'Edit' : 'New'} Webinar`} />

      <Loader isVisible={status.isLoading} />
      <ErrorMessage isVisible={status.isError && !status.isLoading} />

      <div className={styles.container}>
        <Input
          value={webinar.name.value}
          onChange={actions.changeName}
          label="Name"
          isInvalid={!webinar.name.isValid}
        />

        <div className="field">
          <label className="label">Description</label>
          <div className="control">
            <textarea
              className="textarea"
              placeholder="Webinar Description"
              value={webinar.description}
              onChange={actions.changeDescription}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">Date</label>
          <div className="control">
            <DatePicker
              className="profile-input profile-input-sm"
              selected={webinar.date}
              onChange={actions.chnageDate}
              showYearDropdown
              showMonthDropdown
              dateFormat="dd.MM.yyyy"
            />
          </div>
        </div>

        <Input
          value={webinar.price.valueStr}
          onChange={actions.changePrice}
          label="Price"
          isInvalid={!webinar.price.isValid}
        />

        <SaveButton disabled={!status.canSave} onClick={actions.saveData} />
      </div>
    </AdminPageContainer>
  );
};

AdminEditWebinarPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

export default AdminEditWebinarPage;
