import React from 'react';

import AdminPageContainer from './common/AdminPageContainer';
import AdminPageLink from './common/AdminPageLink';

import styles from './AdminPage.module.scss';

const AdminPage = () => {
  return (
    <AdminPageContainer>
      <div className={styles.container}>
        <AdminPageLink to="/admin/users" text="Users" />
        <AdminPageLink to="/admin/requests" text="Requests" />
        <AdminPageLink to="/admin/profession-requests" text="Prof Requests" />
        <AdminPageLink to="/admin/webinars" text="Webinars" />
      </div>
    </AdminPageContainer>
  );
};

export default AdminPage;
