import React from 'react';
import './App.css';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import jwt from 'jsonwebtoken';

import MainPage from './pages/MainPage';
import LoginPage from './pages/LoginPage';
import FrontendPage from './pages/FrontendPage';
import BackendPage from './pages/BackendPage';
import ScrollToTop from './components/common/ScrollToTop';
import RegisterPage from './pages/RegisterPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import reducers from './reducers';
import actions from './actions';
import ProtectedRoute from './components/routes/ProtectedRoute';
import ProfilePage from './pages/ProfilePage';
import AdminRoute from './components/routes/AdminRoute';
import AdminPage from './components/admin/AdminPage';
import AdminUsersPage from './components/admin/users/AdminUsersPage';
import AdminUserTimetablePage from './components/admin/users/timetable/AdminUserTimetablePage';
import AdminPaymentsPage from './components/admin/users/payments/AdminPaymentsPage';
import AdminEditPaymentPage from './components/admin/users/payments/AdminEditPaymentPage';
import AdminRequestsPage from './components/admin/requests/AdminRequestsPage';
import AdminProfessionRequestsPage from './components/admin/profession-requests/AdminProfessionRequestsPage';
import AdminWebinarsPage from './components/admin/webinars/AdminWebinarsPage';
import AdminEditWebinarPage from './components/admin/webinars/AdminEditWebinarPage';
// import AdminUserTimetablePage from './components/admin/users/timetable/AdminUserTimetablePage';
// import AdminPaymentsPage from './components/admin/users/payments/AdminPaymentsPage';
// import AdminEditPaymentPage from './components/admin/users/payments/AdminEditPaymentPage';
// import AdminRequestsPage from './components/admin/requests/AdminRequestsPage';
// import AdminCourseRequestsPage from './components/admin/course-requests/AdminCourseRequestsPage';
// import AdminWebinarsPage from './components/admin/webinars/AdminWebinarsPage';
// import AdminEditWebinarPage from './components/admin/webinars/AdminEditWebinarPage';

const store = createStore(reducers);

// TODO move it to service
const token = localStorage.getItem('token');

if (token) {
  const decoded = jwt.decode(token);

  if (new Date(decoded.exp * 1000) < new Date()) {
    localStorage.removeItem('token');
  } else {
    store.dispatch(actions.authSuccess(token));
  }
}

function App() {
  return (
    <Provider store={store}>
      <HashRouter>
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={MainPage} />
            <Route path="/frontend" component={FrontendPage} />
            <Route path="/backend" component={BackendPage} />
            <Route path="/register" component={RegisterPage} />
            <Route path="/login" component={LoginPage} />
            <Route path="/forgot-password" component={ForgotPasswordPage} />
            <Route path="/reset-password/:token" component={ResetPasswordPage} />

            <ProtectedRoute path="/my" component={ProfilePage} />

            <AdminRoute exact path="/admin" component={AdminPage} />
            <AdminRoute exact path="/admin/users" component={AdminUsersPage} />
            <AdminRoute path="/admin/users/:userId/timetable" component={AdminUserTimetablePage} />
            <AdminRoute exact path="/admin/users/:userId/payments" component={AdminPaymentsPage} />
            <AdminRoute path="/admin/users/:userId/payments/add" component={AdminEditPaymentPage} />
            <AdminRoute path="/admin/users/:userId/payments/:paymentId/edit" component={AdminEditPaymentPage} />
            <AdminRoute path="/admin/requests" component={AdminRequestsPage} />
            <AdminRoute path="/admin/profession-requests" component={AdminProfessionRequestsPage} />
            <AdminRoute exact path="/admin/webinars" component={AdminWebinarsPage} />
            <AdminRoute path="/admin/webinars/add" component={AdminEditWebinarPage} />
            <AdminRoute path="/admin/webinars/:id/edit" component={AdminEditWebinarPage} />
          </Switch>
        </ScrollToTop>
      </HashRouter>
    </Provider>
  );
}

export default App;
