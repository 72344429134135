import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './WhoNeedsScreenInfoBlock.module.scss';

const WhoNeedsScreenInfoBlock = ({ icon, title, children, isRight }) => {
  const containerClassName = classnames(styles.container, {
    [styles.flexRowReverse]: isRight,
  });

  return (
    <div className={containerClassName}>
      {icon}
      <div className={styles.content}>
        <p className={styles.title}>{title}</p>
        <p>{children}</p>
      </div>
    </div>
  );
};

WhoNeedsScreenInfoBlock.defaultProps = {
  isRight: false,
};

WhoNeedsScreenInfoBlock.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isRight: PropTypes.bool,
};

export default WhoNeedsScreenInfoBlock;
