import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field, withFormik } from 'formik';
import axios from 'axios';
import * as yup from 'yup';
import ProfileInput from '../formik/ProfileInput';
// import ProfileDatePickerInput from '../formik/ProfileDatePickerInput';
import SubmitButton from '../formik/SubmitButton';

import styles from './UserSettingsForm.module.scss';
import Alert from '../common/alert/Alert';

const mapPropsToValues = (props) => {
  return {
    name: props.user.name,
    email: props.user.email,
    // birthDate: props.user.birthDate ? new Date(props.user.birthDate) : null,
    phone: props.user.phone || '',
  };
};

const mapPropsToStatus = () => ({});

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  // birthDate: yup.date(),
  phone: yup.string().required(),
});

const handleSubmit = async (values, bag) => {
  bag.setSubmitting(true);

  try {
    await axios.put('/api/v1/profile/update-settings', values, {
      headers: {
        Authorization: `Bearer ${bag.props.token}`,
      },
    });

    bag.setStatus({ isSuccess: true, message: 'Settings has been updated' });
    bag.props.onUpdate(values);
  } catch (e) {
    bag.setStatus({
      isError: true,
      message: (e.response && e.response.data && e.response.data.errorMessage) || 'UNKNOWN ERROR',
    });
  }

  bag.setSubmitting(false);
};

const UserSettingsForm = ({ isSubmitting, status }) => {
  return (
    <Form noValidate>
      {!isSubmitting && status.isSuccess && <Alert message={status.message} isSuccess />}
      {!isSubmitting && status.isError && <Alert message={status.message} isError />}
      <Field
        name="name"
        label="Full name"
        component={ProfileInput}
        disabled={isSubmitting}
        fieldClassName={styles.field}
      />
      <Field name="email" label="Email" component={ProfileInput} fieldClassName={styles.field} disabled />
      {/* <Field
        name="birthDate"
        label="Date of birth"
        component={ProfileDatePickerInput}
        fieldClassName={styles.field}
        disabled={isSubmitting}
      /> */}
      <Field
        name="phone"
        label="Phone"
        component={ProfileInput}
        disabled={isSubmitting}
        fieldClassName={styles.field}
      />
      <SubmitButton
        isSubmitting={isSubmitting}
        title="Save changes"
        type="primary"
        isFixed
        width={150}
        height={40}
        marginTop={30}
        marginBottom={10}
      />
    </Form>
  );
};

UserSettingsForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  token: PropTypes.string.isRequired,
  status: PropTypes.shape({
    isSuccess: PropTypes.bool,
    isError: PropTypes.bool,
    message: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    birthDate: PropTypes.object,
    phone: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  onUpdate: PropTypes.func.isRequired,
};

export default withFormik({
  mapPropsToValues,
  mapPropsToStatus,
  validationSchema,
  handleSubmit,
})(UserSettingsForm);
