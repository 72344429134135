/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';

import AdminPageContainer from '../../common/AdminPageContainer';
import AdminPageTitle from '../../common/AdminPageTitle';
import Loader from '../../../common/loader/Loader';
import ErrorMessage from '../../common/AdminErrorMessage';
import { IconButton, DeleteButton, SaveButton } from '../../common/Buttons';

import useAdminUserTimetablePageState from './useAdminUserTimetablePageState';

import styles from './AdminUserTimetablePage.module.scss';

const AdminUserTimetablePage = ({ match, history }) => {
  const { userId } = match.params;

  const { userName, isDataLoaded, status, timetable, actions } = useAdminUserTimetablePageState(userId, history);

  return (
    <AdminPageContainer>
      <AdminPageTitle to="/admin/users" title={`Timetable for ${userName}`} />

      <Loader isVisible={status.isLoading || status.isSaving} />
      <ErrorMessage isVisible={status.isError && !status.isLoading} />

      {isDataLoaded && (
        <div className={styles.container}>
          {timetable.map((item, monthIndex) => {
            return (
              <div key={item.month} className={styles.row}>
                <div>
                  <div className={styles.button}>
                    <p className={styles.title}>{item.month}</p>
                    <IconButton
                      icon={faPlus}
                      disabled={item.times.length > 2 || status.isSaving}
                      onClick={actions.addTime(monthIndex)}
                    />
                  </div>
                  {item.times.map((time, timeIndex) => {
                    return (
                      <div key={timeIndex} className={styles.dates}>
                        Date:
                        <div>
                          <DatePicker
                            className="profile-input profile-input-sm ml-10px"
                            selected={time}
                            onChange={actions.changeTime(monthIndex, timeIndex)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={30}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                        <DeleteButton
                          className="is-small ml-20px"
                          onClick={actions.deleteTime(monthIndex, timeIndex)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
          <SaveButton className="my-20px" onClick={actions.save} disabled={status.isSaving} />
        </div>
      )}
    </AdminPageContainer>
  );
};

AdminUserTimetablePage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

export default AdminUserTimetablePage;
