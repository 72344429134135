import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, withFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';

import styles from './ContactUsForm.module.scss';

import ProfileInput from '../../formik/ProfileInput';
import SubmitButton from '../../formik/SubmitButton';

const ContactUsForm = ({ isSubmitting }) => {
  return (
    <Form noValidate className={styles.form}>
      <Field name="name" component={ProfileInput} placeholder="Full Name" fieldClassName={styles.field} />
      <Field name="email" component={ProfileInput} placeholder="Email Address" fieldClassName={styles.field} />
      <Field name="phone" component={ProfileInput} placeholder="Phone Number" fieldClassName={styles.field} />
      <SubmitButton title="Send request" type="primary" isFixed width={200} height={50} isSubmitting={isSubmitting} />
    </Form>
  );
};

ContactUsForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
};

const mapPropsToValues = () => ({
  name: '',
  email: '',
  phone: '',
});

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string().required(),
});

const handleSubmit = (values, bag) => {
  bag.setSubmitting(true);

  axios
    .post('/api/v1/messages/request', values)
    .then(() => {
      bag.props.showNotification({
        type: 'success',
        header: 'Message sent',
        text: 'We will contact you soon',
      });
      bag.setSubmitting(false);
    })
    .catch(() => {
      bag.props.showNotification({ type: 'error', header: 'An error occurred', text: 'Try again later' });
      bag.setSubmitting(false);
    });
};

export default withFormik({
  mapPropsToValues,
  validationSchema,
  handleSubmit,
})(ContactUsForm);
