import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as WebinarIcon } from '../../icons/webinar.svg';

import styles from './WebinarBlock.module.scss';
import { OutlineButton } from '../admin/common/Buttons';

const WebinarBlock = ({ webinar }) => {
  return (
    <div className={styles.webinarBlock}>
      <div className={styles.webinarHeader}>
        <div className={styles.webinarHeaderTitle}>
          <WebinarIcon />
          <span>Nearest webinar</span>
        </div>

        <p className={styles.webinarHeaderDate}>{webinar.date}</p>
      </div>
      <div className={styles.webinarContent}>
        <p className={styles.webinarContentTitle}>{webinar.name}</p>
        <p className={styles.webinarContentText}>{webinar.description}</p>
        <p className={styles.webinarContentPrice}>{webinar.price === 0 ? '---' : webinar.price} €</p>
        <OutlineButton onClick={() => {}} text="Participate" />
      </div>
    </div>
  );
};

WebinarBlock.propTypes = {
  webinar: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
  }).isRequired,
};

export default WebinarBlock;
