import React from 'react';
import PropTypes from 'prop-types';

import styles from './ItemIcon.module.scss';
import { professionTypes } from '../../utils/utils';

const ItemIcon = ({ text, type }) => {
  const iconClassName = type === professionTypes.jsBackend ? styles.backend : styles.frontend;

  return (
    <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1" fill="white">
        <path d="M12.9217 37.0512C12.2969 36.9753 11.7265 37.4203 11.6754 38.0476C11.196 43.9332 12.5388 49.8343 15.5361 54.9435C18.7258 60.3809 23.6128 64.6205 29.446 67.0106C35.2792 69.4008 41.736 69.8093 47.824 68.1735C53.9119 66.5376 59.2943 62.9479 63.144 57.9559C66.9936 52.9639 69.0975 46.8459 69.1323 40.5421C69.1672 34.2383 67.131 28.0974 63.3368 23.0632C59.5425 18.0289 54.2002 14.3799 48.1306 12.6769C42.4274 11.0767 36.3788 11.2778 30.8083 13.2372C30.2145 13.4461 29.9292 14.1108 30.1614 14.6958C30.3936 15.2808 31.0553 15.5644 31.6498 15.3575C36.7555 13.5811 42.2925 13.4061 47.5149 14.8714C53.1037 16.4396 58.0229 19.7995 61.5166 24.435C65.0103 29.0705 66.8852 34.725 66.8531 40.5295C66.821 46.334 64.8838 51.9674 61.3391 56.564C57.7943 61.1606 52.8383 64.466 47.2325 65.9723C41.6267 67.4786 35.6814 67.1024 30.3102 64.9015C24.939 62.7007 20.4391 58.797 17.502 53.7903C14.7575 49.1118 13.5195 43.7121 13.9399 38.3226C13.9889 37.6951 13.5465 37.1271 12.9217 37.0512Z" />
      </mask>
      <path
        className={iconClassName}
        d="M12.9217 37.0512C12.2969 36.9753 11.7265 37.4203 11.6754 38.0476C11.196 43.9332 12.5388 49.8343 15.5361 54.9435C18.7258 60.3809 23.6128 64.6205 29.446 67.0106C35.2792 69.4008 41.736 69.8093 47.824 68.1735C53.9119 66.5376 59.2943 62.9479 63.144 57.9559C66.9936 52.9639 69.0975 46.8459 69.1323 40.5421C69.1672 34.2383 67.131 28.0974 63.3368 23.0632C59.5425 18.0289 54.2002 14.3799 48.1306 12.6769C42.4274 11.0767 36.3788 11.2778 30.8083 13.2372C30.2145 13.4461 29.9292 14.1108 30.1614 14.6958C30.3936 15.2808 31.0553 15.5644 31.6498 15.3575C36.7555 13.5811 42.2925 13.4061 47.5149 14.8714C53.1037 16.4396 58.0229 19.7995 61.5166 24.435C65.0103 29.0705 66.8852 34.725 66.8531 40.5295C66.821 46.334 64.8838 51.9674 61.3391 56.564C57.7943 61.1606 52.8383 64.466 47.2325 65.9723C41.6267 67.4786 35.6814 67.1024 30.3102 64.9015C24.939 62.7007 20.4391 58.797 17.502 53.7903C14.7575 49.1118 13.5195 43.7121 13.9399 38.3226C13.9889 37.6951 13.5465 37.1271 12.9217 37.0512Z"
        fill="#F1D600"
        stroke="#F1D600"
        strokeWidth="2"
        mask="url(#path-1-inside-1)"
      />
      <text x="10" y="35" fill="white" fontSize="35px">
        {text}
      </text>
    </svg>
  );
};

ItemIcon.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default ItemIcon;
