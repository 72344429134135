import React from 'react';

import { ReactComponent as WhoNeedsIcon1 } from '../../icons/who-needs-1.svg';
import { ReactComponent as WhoNeedsIcon2 } from '../../icons/who-needs-2.svg';
import { ReactComponent as WhoNeedsIcon3 } from '../../icons/who-needs-3.svg';
import { ReactComponent as WhoNeedsIcon4 } from '../../icons/who-needs-4.svg';

import WhoNeedsScreenInfoBlock from './who-needs-screen/WhoNeedsScreenInfoBlock';

import styles from './WhoNeedsScreen.module.scss';

const WhoNeedsScreen = () => {
  return (
    <div className={styles.wrapper} id="who-needs-container-id">
      <div className={styles.container}>
        <div className={styles.content}>
          <p className={styles.title}>Who needs our training</p>
          <div className={styles.body}>
            <WhoNeedsScreenInfoBlock
              icon={<WhoNeedsIcon1 className={styles.icon} />}
              title="If you want to get a new profession"
              isRight
            >
              You can learn programming from scratch using one of our training programs. Near the end of the program,
              you will receive enough experience to get an interview and start your career as a programmer in a company
              of your dream
            </WhoNeedsScreenInfoBlock>
            <WhoNeedsScreenInfoBlock
              icon={<WhoNeedsIcon2 className={styles.icon} />}
              title="If you want to improve your skills"
            >
              According to our statistics, an experienced developer can significantly increase their professional skills
              using our training program. Most of our student got a promotion at work and fill the gaps in their
              knowledge
            </WhoNeedsScreenInfoBlock>
            <WhoNeedsScreenInfoBlock
              icon={<WhoNeedsIcon3 className={styles.icon} />}
              title="If you want to write an effective and clean code"
              isRight
            >
              Our courses cover a lot of different aspects of web programming. We pay attention to our students on how
              to write really efficient code following high standards of quality. We also teach how to properly work in
              a team using modern techniques
            </WhoNeedsScreenInfoBlock>
            <WhoNeedsScreenInfoBlock
              icon={<WhoNeedsIcon4 className={styles.icon} />}
              title="if you want to get a new job"
            >
              The interviewing process has many different steps and usually requires special training even if you are a
              professional developer with years of experience. Our practical tasks will help you to prepare and get an
              offer for a job
            </WhoNeedsScreenInfoBlock>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoNeedsScreen;
