import React from 'react';
import { Link } from 'react-router-dom';

import AuthLayout from '../components/layouts/AuthLayout';
import LoginForm from '../components/auth/login-form/LoginForm';
import AuthLayoutHeader from '../components/common/auth/AuthLayoutHeader';

import styles from './LoginPage.module.scss';

const LoginPage = () => {
  return (
    <AuthLayout>
      <AuthLayoutHeader isLogin />
      <LoginForm />
      <p className={styles.container}>
        <Link to="/forgot-password" className={styles.link}>
          Forgot password?
        </Link>
      </p>
    </AuthLayout>
  );
};

export default LoginPage;
