import React from 'react';

import AuthLayout from '../components/layouts/AuthLayout';
import ForgotPasswordForm from '../components/auth/forgot-password/ForgotPasswordForm';
import AuthLayoutHeader from '../components/common/auth/AuthLayoutHeader';

const ForgotPasswordPage = () => {
  return (
    <AuthLayout>
      <AuthLayoutHeader />
      <ForgotPasswordForm />
    </AuthLayout>
  );
};

export default ForgotPasswordPage;
