import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, withFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { connect } from 'react-redux';
import { compose } from 'redux';
import SubmitButton from '../formik/SubmitButton';
import Alert from '../common/alert/Alert';
import ProfileInput from '../formik/ProfileInput';

import styles from './UserSettingsForm.module.scss';

const mapPropsToValues = () => ({
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
});

const mapPropsToStatus = () => ({});

const validationSchema = yup.object().shape({
  currentPassword: yup.string().required(),
  newPassword: yup.string().required(),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword')], 'Passwords must match')
    .required(),
});

const getErrorMessage = (error) => {
  if (error.response && error.response.data && error.response.data.errorMessage) {
    return error.response.data.errorMessage;
  }

  return 'Unknown server error';
};

const handleSubmit = async (values, bag) => {
  bag.setSubmitting(true);

  try {
    await axios.put('/api/v1/profile/change-password', values, {
      headers: {
        Authorization: `Bearer ${bag.props.token}`,
      },
    });

    bag.resetForm();
    bag.setStatus({ isSuccess: true, message: 'Password has been successfully changed' });
  } catch (e) {
    bag.resetForm();
    bag.setStatus({ isError: true, message: getErrorMessage(e) });
  }
  bag.setSubmitting(false);
};

const ChangePasswordForm = ({ isSubmitting, status }) => {
  return (
    <Form>
      {!isSubmitting && status.isSuccess && <Alert message={status.message} isSuccess />}
      {!isSubmitting && status.isError && <Alert message={status.message} isError />}
      <Field
        type="password"
        name="currentPassword"
        label="Current password"
        component={ProfileInput}
        disabled={isSubmitting}
        fieldClassName={styles.field}
      />
      <Field
        type="password"
        name="newPassword"
        label="New password"
        component={ProfileInput}
        disabled={isSubmitting}
        fieldClassName={styles.field}
      />
      <Field
        type="password"
        name="confirmNewPassword"
        label="Confirm new password"
        component={ProfileInput}
        disabled={isSubmitting}
        fieldClassName={styles.field}
      />
      <SubmitButton
        isSubmitting={isSubmitting}
        title="Save Changes"
        type="primary"
        isFixed
        width={150}
        height={40}
        marginTop={30}
        marginBottom={10}
      />
    </Form>
  );
};

ChangePasswordForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  token: PropTypes.string.isRequired,
  status: PropTypes.shape({
    isSuccess: PropTypes.bool,
    isError: PropTypes.bool,
    message: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    summary: PropTypes.string,
    currentPassword: PropTypes.string,
    newPassword: PropTypes.string,
    confirmNewPassword: PropTypes.string,
  }).isRequired,
};

export default compose(
  connect(({ token }) => ({ token })),
  withFormik({ mapPropsToValues, mapPropsToStatus, validationSchema, handleSubmit }),
)(ChangePasswordForm);
