import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './AboutBlock.module.scss';
import { professionTypes } from '../../utils/utils';

const AboutBlock = ({ icon, title, text, type }) => {
  const iconClassNames = classnames(styles.icon, {
    [styles.frontendIcon]: type === professionTypes.jsFrontend,
    [styles.backendIcon]: type === professionTypes.jsBackend,
  });

  return (
    <div className={styles.container}>
      <div className={iconClassNames}>{icon}</div>
      <p className={styles.title}>{title}</p>
      <p>{text}</p>
    </div>
  );
};

AboutBlock.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default AboutBlock;
