import React from 'react';

import styles from './WelcomeBox.module.scss';
import HashLinkButton from '../../common/link/HashLinkButton';

const WelcomeBox = () => {
  return (
    <div className={styles.container}>
      Do you want to be
      <br />
      a programmer?
      <HashLinkButton to="/#contact-screen-id" title="Start learning" type="primary" isFixed width={230} height={50} />
    </div>
  );
};

export default WelcomeBox;
