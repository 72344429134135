import React from 'react';
import PropTypes from 'prop-types';

import styles from './StartScreen.module.scss';
import Header from '../common/header/Header';
import WelcomeBox from './start-screen/WelcomeBox';
import StartScreenFooter from './start-screen/StartScreenFooter';

const StartScreen = ({ openSidebar }) => {
  return (
    <div className={styles.container} id="start-screen-container-id">
      <Header openSidebar={openSidebar} />
      <WelcomeBox />
      <StartScreenFooter />
    </div>
  );
};

StartScreen.propTypes = {
  openSidebar: PropTypes.func.isRequired,
};

export default StartScreen;
