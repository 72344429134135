import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './AuthLayouHeader.module.scss';

const AuthLayoutHeader = ({ isLogin, isRegister }) => {
  return (
    <div className={styles.container}>
      <p>
        {!isLogin ? (
          <Link to="/login" className={styles.link}>
            Sign In
          </Link>
        ) : (
          <span className={styles.activeLink}>Sign In</span>
        )}
      </p>
      <p>or</p>
      <p>
        {!isRegister ? (
          <Link to="/register" className={styles.link}>
            Sign Up
          </Link>
        ) : (
          <span className={styles.activeLink}>Sign Up</span>
        )}
      </p>
    </div>
  );
};

AuthLayoutHeader.defaultProps = {
  isLogin: false,
  isRegister: false,
};

AuthLayoutHeader.propTypes = {
  isLogin: PropTypes.bool,
  isRegister: PropTypes.bool,
};

export default AuthLayoutHeader;
