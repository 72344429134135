import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field, withFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import styles from './ResetPasswordForm.module.scss';
import ProfileInput from '../../formik/ProfileInput';
import SubmitButton from '../../formik/SubmitButton';
import Alert from '../../common/alert/Alert';

const ResetPasswordForm = ({ isSubmitting, errors }) => {
  return (
    <Form noValidate>
      {errors.summary && <Alert message={errors.summary} isError />}
      <Field
        name="password"
        type="password"
        component={ProfileInput}
        placeholder="Password"
        disabled={isSubmitting}
        fieldClassName={styles.field}
      />
      <Field
        name="confirmPassword"
        type="password"
        component={ProfileInput}
        placeholder="Confirm Password"
        disabled={isSubmitting}
        fieldClassName={styles.field}
      />
      <SubmitButton isSubmitting={isSubmitting} title="Reset" type="primary" isFullWidth />
    </Form>
  );
};

ResetPasswordForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    summary: PropTypes.string,
  }).isRequired,
};

const mapPropsToValues = () => ({ password: '', confirmPassword: '' });

const validationSchema = yup.object().shape({
  password: yup.string().required(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required(),
});

const handleSubmit = async (values, bag) => {
  let isSuccess = false;
  bag.setSubmitting(true);

  try {
    const response = await axios.post('/api/v1/auth/resetPassword', {
      token: bag.props.token,
      password: values.password,
    });

    if (response.data.success) {
      isSuccess = true;
    } else {
      bag.setErrors({ summary: response.data.errorMessage });
    }
  } catch (e) {
    bag.setErrors({ summary: e.response.data.errorMessage || 'Unknown error' });
  }

  bag.setSubmitting(false);

  if (isSuccess) {
    bag.props.history.push('/login');
  }
};

export default compose(
  withRouter,
  withFormik({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
  }),
)(ResetPasswordForm);
