import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import styles from './LinkButton.module.scss';
import { professionTypes } from '../../../utils/utils';

const LinkButton = ({ to, title, type, isFixed, width, height }) => {
  const btnClassName = classnames(styles.btn, {
    [styles.btnPrimary]: type === 'primary',
    [styles.btnFrontend]: type === professionTypes.jsFrontend,
    [styles.btnBackend]: type === professionTypes.jsBackend,
    [styles.btnFixed]: isFixed,
  });

  let btnStyle = {
    height: `${height}px`,
  };

  if (isFixed) {
    btnStyle = { ...btnStyle, width: `${width}px`, minWidth: `${width}px` };
  }

  return (
    <Link to={to} className={btnClassName} style={btnStyle}>
      {title}
    </Link>
  );
};

LinkButton.defaultProps = {
  type: 'default',
  isFixed: false,
  width: 100,
  height: 50,
};

LinkButton.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  type: PropTypes.string,
  isFixed: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default LinkButton;
