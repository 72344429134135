/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import styles from './Modal.module.scss';

const Modal = (props) => {
  if (!props.isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={styles.container} onClick={props.close}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {props.children}
      </div>
    </div>,
    document.getElementById('modal-root'),
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default Modal;
