import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { useConfirmationModal } from '../../common/modals/ConfirmationModal';
import { checkUserAuth } from '../../../utils/auth-utils';

const useAdminRequestsPageState = (history) => {
  const { token } = useSelector((state) => state);

  const [status, setStatus] = useState({ isLoading: false, isError: false });
  const [data, setData] = useState([]);

  useEffect(() => {
    setStatus({ isLoading: true, isError: false });

    axios
      .get('/api/v1/admin/course-requests', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r) => {
        setStatus({ isLoading: false, isError: false });
        setData(r.data.requests);
      })
      .catch((e) => {
        setStatus({ isLoading: false, isError: true });
        checkUserAuth(e, history);
      });
  }, []);

  const deleteConfirmation = useConfirmationModal();

  const openDeleteConfirmation = (id) => () => {
    // Double function here because if you pass function as arg it will execute it
    // useState takes the initial value of the state variable as an argument.
    // You can pass it directly, as shown in the previous example, or use a function to lazily initialize the variable
    // (useful when the initial state is the result of an expensive computation):
    deleteConfirmation.open(() => () => {
      setStatus({ isLoading: true, isError: false });

      axios
        .delete(`/api/v1/admin/course-requests/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          setData(data.filter((x) => x._id !== id));
          setStatus({ isLoading: false, isError: false });
        })
        .catch((e) => {
          setStatus({ isLoading: false, isError: true });
          checkUserAuth(e, history);
        });
    });
  };

  return {
    status,
    data,
    openDeleteConfirmation,
    deleteConfirmation,
  };
};

export default useAdminRequestsPageState;
