import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { HashLink } from 'react-router-hash-link';

import styles from './LinkButton.module.scss';

const HashLinkButton = ({ isFixed, width, height, to, title, type }) => {
  const btnClassName = classnames(styles.btn, {
    [styles.btnPrimary]: type === 'primary',
    [styles.btnFixed]: isFixed,
  });

  const btnStyle = !isFixed ? {} : { width: `${width}px`, minWidth: `${width}px`, height: `${height}px` };

  return (
    <HashLink to={to} className={btnClassName} style={btnStyle} smooth>
      {title}
    </HashLink>
  );
};

HashLinkButton.defaultProps = {
  type: 'default',
  isFixed: false,
  width: 100,
  height: 50,
};

HashLinkButton.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  type: PropTypes.string,
  isFixed: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default HashLinkButton;
