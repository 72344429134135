import React from 'react';

import { Link } from 'react-router-dom';

import styles from './AdminHeader.module.scss';
import Logo from '../../common/logo/Logo';

const AdminHeader = () => {
  return (
    <div className={styles.container}>
      <Logo />
      <Link to="/my" className={styles.link}>
        Go to profile
      </Link>
    </div>
  );
};

export default AdminHeader;
