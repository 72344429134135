import React, { useState } from 'react';

import styles from './ContactScreen.module.scss';

import ContactUsForm from './contact-screen/ContactUsForm';
import NotificationModal from '../common/modals/NotificationModal';

const ContactScreen = () => {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationData, setNotificationData] = useState({ type: '', header: '', text: '' });

  const closeNotification = () => {
    setIsNotificationOpen(false);
  };

  const openNotification = (type) => {
    setIsNotificationOpen(true);
    setNotificationData(type);
  };

  return (
    <>
      <div className={styles.wrapper} id="contact-screen-id">
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.title}>Contact Us</div>
              <div className={styles.text}>
                <p>
                  Get a new profession and start a career using one of the most popular programming languages in the
                  world. Improve your skills and get a promotion on your current work or get an offer in a company of
                  your dream.
                </p>
                <p>
                  Fill the form and we contact you for more information about your goals. Hurry up! We have discounts
                  for the first 20 students who will buy a course.
                </p>
              </div>
            </div>
            <div>
              <ContactUsForm showNotification={openNotification} />
            </div>
          </div>
        </div>
      </div>
      <NotificationModal isOpen={isNotificationOpen} close={closeNotification} data={notificationData} />
    </>
  );
};

export default ContactScreen;
