import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './Alert.module.scss';

const Alert = ({ message, isError, isSuccess }) => {
  const classNames = classnames(styles.alert, {
    [styles.alertSuccess]: isSuccess,
    [styles.alertError]: isError,
  });

  return <div className={classNames}>{message}</div>;
};

Alert.defaultProps = {
  isError: false,
  isSuccess: false,
};

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  isError: PropTypes.bool,
  isSuccess: PropTypes.bool,
};

export default Alert;
