import React from 'react';
import ProfessionPageTemplate from '../components/profession/ProfessionPageTemplate';
import { ReactComponent as BackendAboutIcon1 } from '../icons/backend-about-1.svg';
import { ReactComponent as BackendAboutIcon2 } from '../icons/backend-about-2.svg';
import { ReactComponent as BackendAboutIcon3 } from '../icons/backend-about-3.svg';
import { professionTypes } from '../utils/utils';

const data = {
  type: professionTypes.jsBackend,
  name: 'Javascript backend developer',
  agenda: ['Learn JavaScript basics', 'Understand how to use Node.js', 'Create your first backend app'],
  description: {
    paragraphs: [
      'JavaScript is one of the most popular programming language in the world. It is used for creating modern and fast web applications',
      'You will learn how to use the language for creating web servers using Express.js and MongoDb',
      'You will learn JavaScript through the practice. Our program includes many hours of practical lessons',
      'At the end of the course you will create you first web server that you can use for your portfolio',
    ],
    blocks: [
      {
        icon: <BackendAboutIcon1 />,
        title: 'Modern JS',
        text:
          'Use all modern features from ES standard. Learn object oriented programming basics, functional programming basics and design patterns',
      },
      {
        icon: <BackendAboutIcon2 />,
        title: 'Node.js, Express.js, MongoDb',
        text: 'Learn Node.js, Express.js and web server architecture. Learn how to create a web server from scratch',
      },
      {
        icon: <BackendAboutIcon3 />,
        title: 'Debug and Test',
        text:
          'Learn how to use features for testing backend applications and how to deploy it. Learn how to write your own tests',
      },
    ],
  },
  program: [
    {
      name: 'Introduction to programming',
      theoryCount: 4,
      practiceCount: 1,
      lessons: ['What is the programming?', 'Who is a programmer?', 'Programmer career', 'Programming companies types'],
    },
    {
      name: 'Introduction to JavaScript',
      theoryCount: 24,
      practiceCount: 15,
      lessons: [
        'Introduction',
        'History of JavaScript',
        'First Program',
        'JavaScript data types',
        'Variables',
        'Conditionals',
        'Loops',
        'Functions',
        'Arrays',
        'Scope',
        'Closures',
        'Iterators',
        'Objects',
        'Classes',
      ],
    },
    {
      name: 'GIT Basics',
      theoryCount: 18,
      practiceCount: 4,
      lessons: [
        'Introduction',
        'What is version control system?',
        'GIT basics',
        'Git Fundamentals',
        'GIT Branches',
        'GIT Distributed',
        'Introduction to GitHub',
        'GitHub basics',
      ],
    },
    {
      name: 'Command line basics',
      theoryCount: 17,
      practiceCount: 3,
      lessons: [
        'Introduction',
        'Operation Systems',
        'Command Line Interface',
        'Command Line Navigation',
        'File Structure',
        'Operations with Files and Folders',
        'Pipelines',
        'Environment Variables',
        'User and Groups',
        'Package Manager',
        'Program Execution',
      ],
    },
    {
      name: 'Project setup',
      theoryCount: 15,
      practiceCount: 2,
      lessons: [
        'Introduction',
        'NPM',
        'Dependencies',
        'Lock File',
        'Scripts',
        'Publishing',
        'Executable vs Libraries',
        'Versioning',
        'NPX',
      ],
    },
    {
      name: 'Introduction to web development',
      theoryCount: 10,
      practiceCount: 3,
      lessons: [
        'Introduction',
        'What is HTML?',
        'What is CSS?',
        'What is Scripts?',
        'Hosting Providers',
        'Frontend vs Backend',
      ],
    },
    {
      name: 'JavaScript arrays',
      theoryCount: 48,
      practiceCount: 15,
      lessons: [
        'Introduction',
        'Array basics',
        'for Loop',
        'for...of Loop',
        'References',
        'Mutable vs Immutable',
        'Jagged Arrays',
        'break, continue',
        'Strings and Arrays',
        'Stack',
        'Queue',
        'Destructuring',
        'Rest operator',
        'Spread operator',
        'Memory and Complexity',
      ],
    },
    {
      name: 'JavaScript objects',
      theoryCount: 16,
      practiceCount: 9,
      lessons: [
        'Introduction',
        'Object basics',
        'Map vs Object',
        'Arrays vs Object',
        'Work with Object Properties',
        'Destructuring',
        'Spread operator',
      ],
    },
    {
      name: 'JavaScript functions',
      theoryCount: 14,
      practiceCount: 10,
      lessons: [
        'Introduction',
        'Basics',
        'High Order Functions',
        'Pure Functions',
        'Rest operator',
        'Spread operator',
        'Functions vs Objects',
        'Programming with Functions',
      ],
    },
    {
      name: 'How to test JS applications',
      theoryCount: 20,
      practiceCount: 6,
      lessons: [
        'Introduction',
        'What is Tests?',
        'Test Configuration with Jest',
        'First Test with Jest',
        'Jest Matchers',
        'Unit Test',
        'Integration Tests',
        'Code Coverage',
        'Test Driven Development',
        'Continuous Integration',
        'Best Practices of Testing',
      ],
    },
    {
      name: 'JavaScript asynchronous programming',
      theoryCount: 22,
      practiceCount: 16,
      lessons: [
        'Introduction',
        'Call Stack',
        'First async Example',
        'Timers',
        'Parallel Execution',
        'Event Loop',
        'Callbacks',
        'Promises',
        'Error Handling',
        'Async/Await',
      ],
    },
    {
      name: 'HTTP protocol',
      theoryCount: 24,
      practiceCount: 6,
      lessons: [
        'Introduction',
        'HTTP 1.0',
        'HTTP 1.1',
        'HTTP Body',
        'Send Form Data',
        'Encoding',
        'Query String',
        'Redirect',
        'Cookies',
      ],
    },
    {
      name: 'HTTP Server',
      theoryCount: 32,
      practiceCount: 8,
      lessons: [
        'Introduction',
        'What is HTTP Server?',
        'JSON',
        'cURL',
        'Request',
        'Response',
        'Routing',
        'Processing Requests',
        'Authorization',
      ],
    },
    {
      name: 'Introduction to Express.js',
      theoryCount: 28,
      practiceCount: 15,
      lessons: [
        'Introduction',
        'Installing',
        'Basic Example',
        'Basic Routing',
        'Static Files',
        'Body Parser',
        'Logging',
        'Debugging',
      ],
    },
    {
      name: 'Advanced Express.js features',
      theoryCount: 37,
      practiceCount: 21,
      lessons: [
        'Introduction',
        'Advanced Routing',
        'Middlewares',
        'Writing Middleware',
        'Using Middleware',
        'Error Handling',
        'Using Templates',
        'Sessions',
        'Authorization',
        'JWT',
        'Database Integration',
      ],
    },
    {
      name: 'Web server architecture',
      theoryCount: 14,
      practiceCount: 1,
      lessons: ['Introduction', 'Concurrency', 'Multi-threading', 'Hybrid'],
    },
    {
      name: 'Introduction to MongoDb',
      theoryCount: 65,
      practiceCount: 32,
      lessons: [
        'Introduction',
        'Setup',
        'Databases',
        'Collections',
        'Documents',
        'Indexes',
        'Query Document',
        'Insert Document',
        'Update Document',
        'Remove Document',
        'Projection',
        'Limiting Records',
        'Sorting Records',
        'Mongoose',
        'Setup Mongoose with Express',
      ],
    },
    {
      name: 'Typescript basics',
      theoryCount: 44,
      practiceCount: 23,
      lessons: [
        'Introduction',
        'Basic Types',
        'Variables',
        'Interfaces',
        'Classes',
        'Functions',
        'Enums',
        'Generics',
        'Type Inference',
        'Typescript and Express',
        'Typescript and Mongoose',
      ],
    },
    {
      name: 'Advanced testing',
      theoryCount: 44,
      practiceCount: 15,
      lessons: [
        'Introduction',
        'Error Testing',
        'Fixtures',
        'Side Effects',
        'Integration Testing',
        'HTTP Requests Testing',
        'Express.js Testing',
        'MongoDb Testing',
      ],
    },
    {
      name: 'Create your first app',
      theoryCount: 2,
      practiceCount: 100,
      lessons: ['Introduction', 'Create your first React&Redux App'],
    },
  ],
  price: {
    current: 2999,
    old: 6000,
  },
  discount: {
    percent: 21,
    studentsCount: 19,
  },
};

const BackendPage = () => {
  return <ProfessionPageTemplate data={data} />;
};

export default BackendPage;
