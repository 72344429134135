// eslint-disable-next-line import/prefer-default-export
export const checkUserAuth = (error, history) => {
  if (error && error.response && error.response.status) {
    const { status } = error.response;

    if (status === 401 || status === 403) {
      history.push('/auth');
    }
  }
};
