import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CaretUpIcon } from '../../icons/caret-up.svg';
import { ReactComponent as CaretDownIcon } from '../../icons/caret-down.svg';

import styles from './CollapsableBlock.module.scss';

const CollapsableBlock = ({ isOpen: isOpenByDefault, title, children }) => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault);

  const toggleBlockOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className={styles.header} onClick={toggleBlockOpen}>
          <p>{title}</p>
          <div>{!isOpen ? <CaretDownIcon /> : <CaretUpIcon />}</div>
        </div>
        {isOpen && <div className={styles.content}>{children}</div>}
      </div>
    </div>
  );
};

CollapsableBlock.defaultProps = {
  isOpen: false,
};

CollapsableBlock.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CollapsableBlock;
