import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'react-spinners-css';

import styles from './Loader.module.scss';

const Loader = ({ isVisible }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.loader}>
      <Grid color="#4ad295" />
    </div>
  );
};

Loader.defaultProps = {
  isVisible: false,
};

Loader.propTypes = {
  isVisible: PropTypes.bool,
};

export default Loader;
