import { handleActions } from 'redux-actions';
import jwt from 'jsonwebtoken';

export default handleActions(
  {
    AUTH_SUCCESS: (state, action) => {
      const decoded = jwt.decode(action.payload.token);
      return { token: action.payload.token, user: decoded, isAuthenticated: true };
    },
    AUTH_LOGOUT: () => {
      localStorage.removeItem('token');
      return { isAuthenticated: false };
    },
  },
  { isAuthenticated: false },
);
