import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { faUserCog, faUser, faFileInvoiceDollar, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

import axios from 'axios';

import AdminPageContainer from '../common/AdminPageContainer';

import Loader from '../../common/loader/Loader';

import { BackLink, IconLink, IconButton } from '../common/Buttons';
import { checkUserAuth } from '../../../utils/auth-utils';
import ConfirmationModal, { useConfirmationModal } from '../../common/modals/ConfirmationModal';
import Alert from '../../common/alert/Alert';

import styles from './AdminUsersPage.module.scss';

const AdminUsersPage = (props) => {
  const { token, user: currentUser } = useSelector((state) => state);

  const [users, setUsers] = useState([]);
  const [status, setStatus] = useState({ isLoading: false, isError: false });

  const loadData = () => {
    setStatus({ isLoading: true, isError: false });
    axios
      .get('/api/v1/admin/users', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r) => {
        setStatus({ isLoading: false, isError: false });
        setUsers(r.data.users);
      })
      .catch((e) => {
        setStatus({ isLoading: false, isError: true });
        checkUserAuth(e, props.history);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const changeIsActive = (userId) => (value) => {
    setStatus({ isLoading: true, isError: false });
    axios
      .put(
        `/api/v1/admin/users/${userId}/changeIsActive`,
        { isActive: value },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(() => {
        loadData();
      })
      .catch((e) => {
        setStatus({ isLoading: false, isError: true });
        checkUserAuth(e, props.history);
      });
  };

  const changeIsAdmin = (userId) => (value) => {
    setStatus({ isLoading: true, isError: false });
    axios
      .put(
        `/api/v1/admin/users/${userId}/changeIsAdmin`,
        { isActive: value },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(() => {
        loadData();
      })
      .catch((e) => {
        setStatus({ isLoading: false, isError: true });
        checkUserAuth(e, props.history);
      });
  };

  const confirmation = useConfirmationModal();

  const openDeactivateConfirmation = (userId) => () => {
    confirmation.open(() => () => {
      changeIsActive(userId)(false);
    });
  };

  const openActivateConfirmation = (userId) => () => {
    confirmation.open(() => () => {
      changeIsActive(userId)(true);
    });
  };

  const openSetAdminConfirmation = (userId) => () => {
    confirmation.open(() => () => {
      changeIsAdmin(userId)(true);
    });
  };

  const openUnsetAdminConrifmation = (userId) => () => {
    confirmation.open(() => () => {
      changeIsAdmin(userId)(false);
    });
  };

  return (
    <AdminPageContainer>
      <Loader isVisible={status.isLoading} />
      {status.isError && <Alert isError message="An unknown error occured" />}
      <ConfirmationModal state={confirmation} />

      <BackLink to="/admin" />

      <div className={styles.container}>
        {users.map((user) => (
          <div key={user._id} className={styles.row}>
            <span className={styles.rowTitle}>{user.name}</span>
            <div className={styles.rowButtons}>
              <IconLink to={`/admin/users/${user._id}/payments`} icon={faFileInvoiceDollar} text="Payments" />
              <IconLink to={`/admin/users/${user._id}/timetable`} icon={faCalendar} text="Timetable" />
              {!user.isOwner && user._id !== currentUser.id && (
                <>
                  {!user.isAdmin && (
                    <IconButton onClick={openSetAdminConfirmation(user._id)} text="Set Admin" icon={faUserCog} />
                  )}
                  {user.isAdmin && (
                    <IconButton onClick={openUnsetAdminConrifmation(user._id)} text="Unset Admin" icon={faUserCog} />
                  )}
                  {user.isActive && (
                    <IconButton onClick={openDeactivateConfirmation(user._id)} text="Deactivate" icon={faUser} />
                  )}
                  {!user.isActive && (
                    <IconButton onClick={openActivateConfirmation(user._id)} text="Activate" icon={faUserCog} />
                  )}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </AdminPageContainer>
  );
};

AdminUsersPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

export default AdminUsersPage;
