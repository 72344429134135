import React from 'react';
import PropTypes from 'prop-types';
import AuthLayoutHeader from '../components/common/auth/AuthLayoutHeader';
import AuthLayout from '../components/layouts/AuthLayout';
import ResetPasswordForm from '../components/auth/reset-password/ResetPasswordForm';

const ResetPasswordPage = ({
  match: {
    params: { token },
  },
}) => {
  return (
    <AuthLayout>
      <AuthLayoutHeader />
      <ResetPasswordForm token={token} />
    </AuthLayout>
  );
};

ResetPasswordPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ResetPasswordPage;
