/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

import SidebarHashLink from './SidebarHashLink';

import actions from '../../../actions';

import styles from './SidebarContent.module.scss';
import linkStyles from './SidebarHashLink.module.scss';

const SidebarContent = ({ closeSidebar }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const logout = (e) => {
    e.preventDefault();
    dispatch(actions.authLogout());
    history.push('/login');
  };

  const { isAuthenticated } = useSelector((state) => state);

  return (
    <>
      <SidebarHashLink containerId="start-screen-container-id" onClick={closeSidebar} name="Main" />
      <SidebarHashLink containerId="about-screen-container-id" onClick={closeSidebar} name="About" />
      <SidebarHashLink containerId="who-needs-container-id" onClick={closeSidebar} name="Who needs" />
      <SidebarHashLink containerId="programs-container-id" onClick={closeSidebar} name="Programs" />
      <SidebarHashLink containerId="contact-screen-id" onClick={closeSidebar} name="Contacts" />

      <div className={styles.separator} />
      {!isAuthenticated && (
        <Link to="/login" className={linkStyles.link}>
          <div className="is-flex align-items-center">
            <span className="mr-10px">Sign In</span>
          </div>
        </Link>
      )}
      {isAuthenticated && (
        <>
          <Link to="/my" className={linkStyles.link}>
            Profile
          </Link>
          <a href="#" onClick={logout} className={linkStyles.link}>
            Sign out
          </a>
        </>
      )}
    </>
  );
};

SidebarContent.propTypes = {
  closeSidebar: PropTypes.func.isRequired,
};

export default SidebarContent;
