import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './ProgramBlock.module.scss';
import { ReactComponent as ClockIcon } from '../../icons/clock.svg';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as MinusIcon } from '../../icons/minus.svg';
import ItemIcon from './ItemIcon';
import { professionTypes } from '../../utils/utils';

const ProgramBlock = ({ blockIndex, title, lessons, theoryCount, practiceCount, type }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDetails = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const expandIconClassNames = classnames(styles.expandIcon, {
    [styles.expandIconFrontend]: type === professionTypes.jsFrontend,
    [styles.expandIconBackend]: type === professionTypes.jsBackend,
  });

  return (
    <div className={styles.container}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className={styles.header} onClick={toggleDetails}>
        <div className={styles.content}>
          <div className={styles.programBlock}>
            <ItemIcon text={blockIndex + 1} type={type} />
            <div className={styles.programNameBlock}>
              <p className={styles.title}>{title}</p>
              <p>{lessons.length} lessons</p>
            </div>
          </div>
          <div className={styles.programBlock}>
            <p className={styles.hours}>
              <ClockIcon />
            </p>
            <div className={styles.hoursBlock}>
              <p className={styles.hours}>{theoryCount} hours of theory</p>
              <p className={styles.hours}>{practiceCount} hours of practice</p>
            </div>
          </div>
        </div>
        <div className={styles.openLinkBlock}>
          {!isOpen ? <PlusIcon className={expandIconClassNames} /> : <MinusIcon className={expandIconClassNames} />}
        </div>
      </div>
      {isOpen && (
        <div className={styles.body}>
          {lessons.map((lessonName, lessonIndex) => {
            return (
              <div className={styles.lesson}>
                <div>
                  {blockIndex + 1}.{lessonIndex + 1}
                </div>
                <div className={styles.lessonName}>{lessonName}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

ProgramBlock.propTypes = {
  blockIndex: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  lessons: PropTypes.arrayOf(PropTypes.string).isRequired,
  theoryCount: PropTypes.number.isRequired,
  practiceCount: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

export default ProgramBlock;
