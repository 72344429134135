/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

import SidebarHashLink from './SidebarHashLink';

import actions from '../../../actions';

import styles from './SidebarContent.module.scss';
import linkStyles from './SidebarHashLink.module.scss';

const ProgramPageSidebarContent = ({ closeSidebar, type }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const logout = (e) => {
    e.preventDefault();
    dispatch(actions.authLogout());
    history.push('/login');
  };

  const { isAuthenticated } = useSelector((state) => state);

  const path = type === 'js-backend' ? 'backend' : 'frontend';

  return (
    <>
      <SidebarHashLink path={path} containerId="page-top-id" onClick={closeSidebar} name="Top" />
      <SidebarHashLink path={path} containerId="page-about-id" onClick={closeSidebar} name="About" />
      <SidebarHashLink path={path} containerId="page-program-id" onClick={closeSidebar} name="Program" />
      <SidebarHashLink path={path} containerId="page-pricing-id" onClick={closeSidebar} name="Pricing" />

      <div className={styles.separator} />
      {!isAuthenticated && (
        <Link to="/login" className={linkStyles.link}>
          <div className="is-flex align-items-center">
            <span className="mr-10px">Sign In</span>
          </div>
        </Link>
      )}
      {isAuthenticated && (
        <>
          <Link to="/my" className={linkStyles.link}>
            Profile
          </Link>
          <a href="#" onClick={logout} className={linkStyles.link}>
            Sign out
          </a>
        </>
      )}
    </>
  );
};

ProgramPageSidebarContent.propTypes = {
  closeSidebar: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default ProgramPageSidebarContent;
