/* eslint-disable jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import classnames from 'classnames';

import CloseIcon from '../icons/CloseIcon';

import styles from './NotificationModal.module.scss';

import { ReactComponent as ErrorIcon } from '../../../icons/error.svg';
import { ReactComponent as SuccessIcon } from '../../../icons/success.svg';

const stopPropagationEventHandler = (e) => {
  e.stopPropagation();
};

const NotificationModal = (props) => {
  if (!props.isOpen) {
    return null;
  }

  const closeModal = (e) => {
    e.preventDefault();
    props.close();
  };

  const headerClassNames = classnames(styles.modalNotificationHeader, {
    [styles.successHeader]: props.data.type !== 'error',
    [styles.errorHeader]: props.data.type === 'error',
  });

  const bodyClassNames = classnames(
    styles.modalNotificationBody,
    props.data.type !== 'error' ? styles.bodySuccess : styles.bodyError,
  );

  return ReactDOM.createPortal(
    <div className={styles.modalRoot} onClick={props.close}>
      <div onClick={stopPropagationEventHandler} className={styles.modalNotificationContainer}>
        <div className={headerClassNames}>
          <span className={styles.headerTitle}>{props.data.type !== 'error' ? 'Success' : 'Error'}</span>
          <button type="button" onClick={closeModal} className={styles.iconButton}>
            <CloseIcon color="white" opacity={1} />
          </button>
        </div>
        <div className={bodyClassNames}>
          <div className={styles.title}>
            {props.data.type === 'error' ? <ErrorIcon /> : <SuccessIcon />}
            {props.data.header && <p className={styles.modalNotificationCaption}>{props.data.header}</p>}
          </div>
          {props.data.text && <p className={styles.modalNotificationText}>{props.data.text}</p>}
        </div>
      </div>
    </div>,
    document.getElementById('modal-root'),
  );
};

NotificationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  data: PropTypes.shape({
    type: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
};

export default NotificationModal;
