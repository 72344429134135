/* eslint-disable react/no-array-index-key,jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import AdminPageContainer from '../../common/AdminPageContainer';
import AdminPageTitle from '../../common/AdminPageTitle';
import Loader from '../../../common/loader/Loader';
import ErrorMessage from '../../common/AdminErrorMessage';
import Input from '../../common/DefaultInput';

import { SaveButton, IconButton, DeleteButton } from '../../common/Buttons';

import useAdminEditPaymentPageState from './useAdminEditPaymentPageState';

import styles from './AdminEditPaymentPage.module.scss';

const AdminEditPaymentPage = ({ match, history }) => {
  const { userId, paymentId } = match.params;

  const { isDataLoaded, userName, status, payment, errors, actions } = useAdminEditPaymentPageState(
    userId,
    paymentId,
    history,
  );

  return (
    <AdminPageContainer>
      <AdminPageTitle to={`/admin/users/${userId}/payments`} title={`Payment for ${userName}`} />

      <Loader isVisible={status.isLoading} />
      <ErrorMessage isVisible={status.isError} />

      {isDataLoaded && (
        <div className={styles.container}>
          <div className="field">
            <label className="label">Date</label>
            <div className="control">
              <DatePicker
                className="profile-input profile-input-sm"
                selected={payment.date}
                onChange={actions.changeDate}
                showYearDropdown
                showMonthDropdown
                dateFormat="dd.MM.yyyy"
              />
            </div>
          </div>

          <Input
            value={payment.client.name}
            label="Client Name"
            onChange={actions.changeClientName}
            isInvalid={errors.client.name}
          />
          <Input
            value={payment.client.address}
            label="Client Address"
            isInvalid={errors.client.address}
            onChange={actions.changeClientAddress}
          />

          <div className="mt-20px">
            <IconButton text="Add Item" icon={faPlus} className="button is-link" onClick={actions.addItem} />

            {payment.items.length > 0 && (
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th rowSpan="2" className="cell-center" style={{ width: '50px' }}>
                      Item
                    </th>
                    <th rowSpan="2" className="cell-center" style={{ width: '100px' }}>
                      Qty
                    </th>
                    <th rowSpan="2" className="cell-center">
                      Description
                    </th>
                    <th className="cell-center" style={{ width: '100px' }}>
                      Unit Price
                    </th>
                    <th className="cell-center" style={{ width: '100px' }}>
                      Value Of Supply
                    </th>
                    <th className="cell-center" style={{ width: '100px' }}>
                      Vat
                    </th>
                    <th rowSpan="2" />
                  </tr>
                  <tr>
                    <th className="cell-center">EUR</th>
                    <th className="cell-center">EUR</th>
                    <th className="cell-center">EUR</th>
                  </tr>
                </thead>
                <tbody>
                  {payment.items.map((item, index) => (
                    <tr key={index}>
                      <td className="cell-center">{index + 1}</td>
                      <td>
                        <Input
                          value={item.count}
                          onChange={actions.changeItemCount(index)}
                          isInvalid={(errors.items[index] && errors.items[index].count) || false}
                        />
                      </td>
                      <td>
                        <Input
                          value={item.description}
                          onChange={actions.changeItemDescription(index)}
                          isInvalid={(errors.items[index] && errors.items[index].description) || false}
                        />
                      </td>
                      <td>
                        <Input
                          value={item.price}
                          onChange={actions.changeItemPrice(index)}
                          isInvalid={(errors.items[index] && errors.items[index].price) || false}
                        />
                      </td>
                      <td>{item.total}</td>
                      <td>
                        <Input
                          value={item.rate}
                          onChange={actions.changeItemRate(index)}
                          isInvalid={(errors.items[index] && errors.items[index].rate) || false}
                        />
                      </td>
                      <td>
                        <DeleteButton onClick={actions.deleteItem(index)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

          <div className="mt-20px">Total Price: {payment.total}</div>

          <SaveButton className="mt-20px" disabled={status.isSaving || !status.canSave} onClick={actions.save} />
        </div>
      )}
    </AdminPageContainer>
  );
};

AdminEditPaymentPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string.isRequired,
      paymentId: PropTypes.string,
    }).isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

export default AdminEditPaymentPage;
