import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useConfirmationModal } from '../../../common/modals/ConfirmationModal';

const useAdminPaymentsPageState = (userId) => {
  const { token } = useSelector((state) => state);

  const [status, setStatus] = useState({ isLoading: false, isError: false });
  const [payments, setPayments] = useState([]);
  const [userName, setUserName] = useState('');
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    setStatus({ isLoading: true, isError: false });
    axios
      .get(`/api/v1/admin/users/${userId}/payments`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUserName(response.data.userName);
        setPayments(response.data.payments);
        setStatus({ isLoading: false, isError: false });
        setIsDataLoaded(true);
      })
      .catch(() => {
        setStatus({ isLoading: false, isError: true });
      });
  }, []);

  const deleteConfirmation = useConfirmationModal();

  const openDeleteConfirmation = (id) => () => {
    // Double function here because if you pass function as arg it will execute it
    // useState takes the initial value of the state variable as an argument.
    // You can pass it directly, as shown in the previous example, or use a function to lazily initialize the variable
    // (useful when the initial state is the result of an expensive computation):
    deleteConfirmation.open(() => () => {
      setStatus({ isLoading: true, isError: false });

      axios
        .delete(`/api/v1/admin/users/${userId}/payments/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          setPayments(payments.filter((x) => x._id !== id));
          setStatus({ isLoading: false, isError: false });
        })
        .catch(() => {
          setStatus({ isLoading: false, isError: true });
        });
    });
  };

  return {
    userName,
    isDataLoaded,
    status,
    payments,
    deleteConfirmation,
    openDeleteConfirmation,
  };
};

export default useAdminPaymentsPageState;
