/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { ReactComponent as TimetableIcon } from '../../icons/timetable.svg';

import styles from './TimetableBlock.module.scss';

const TimetableBlock = ({ timetable }) => {
  return (
    <div className={styles.timetableBlock}>
      <div className={styles.timetableHeader}>
        <div className={styles.timetableHeaderTitle}>
          <TimetableIcon />
          <span>My timetable</span>
        </div>
      </div>
      <div className={styles.timetableContent}>
        {timetable.map((item) => (
          <div key={item.month} className={styles.timetableDay}>
            {item.month}
            {item.times.map((time, timeIndex) => (
              <div
                key={timeIndex}
                className={styles.timetableTime}
                style={{ left: 0, top: `${50 * (timeIndex + 1)}px`, width: '100%' }}
              >
                <div className={styles.time}>
                  <div className={styles.timetableCircle} />
                  <span>{moment(time).format('h:mm')}</span>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

TimetableBlock.propTypes = {
  timetable: PropTypes.arrayOf(
    PropTypes.shape({
      month: PropTypes.string.isRequired,
      times: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ).isRequired,
};

export default TimetableBlock;
