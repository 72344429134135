import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './AuthLayout.module.scss';
import Logo from '../common/logo/Logo';

import { ReactComponent as CloseIcon } from '../../icons/close.svg';

const AuthLayout = ({ children }) => {
  return (
    <div className={styles.layout}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Logo />
          <Link to="/" className={styles.closeLink}>
            <CloseIcon />
          </Link>
        </div>
        <div className={styles.content}>
          <div className={styles.authContainerBody}>{children}</div>
        </div>
      </div>
    </div>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthLayout;
