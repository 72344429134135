import React from 'react';
import PropTypes from 'prop-types';
import { HashLink } from 'react-router-hash-link';

import styles from './SidebarHashLink.module.scss';

const SidebarHashLink = ({ path, containerId, onClick, name }) => {
  return (
    <HashLink to={`/${path}#${containerId}`} className={styles.link} onClick={onClick} smooth>
      {name}
    </HashLink>
  );
};

SidebarHashLink.defaultProps = {
  path: '',
};

SidebarHashLink.propTypes = {
  containerId: PropTypes.string.isRequired,
  path: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SidebarHashLink;
