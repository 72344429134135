import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import styles from './ProfessionBlock.module.scss';
import { ReactComponent as JsFrontendIcon } from '../../../icons/js-frontend.svg';
import { ReactComponent as JsBackendIcon } from '../../../icons/js-backend.svg';
import { ReactComponent as ListItemIcon } from '../../../icons/list-item.svg';
import { ReactComponent as WebpackIcon } from '../../../icons/webpack.svg';
import { ReactComponent as ReactIcon } from '../../../icons/react.svg';
import { ReactComponent as BootstrapIcon } from '../../../icons/bootstrap.svg';
import { ReactComponent as ArrowRightIcon } from '../../../icons/arrow-right.svg';
import { ReactComponent as ExpressIcon } from '../../../icons/express.svg';
import { ReactComponent as MongodbIcon } from '../../../icons/mongodb.svg';
import { professionTypes } from '../../../utils/utils';

const ProfessionBlock = ({ title, duration, type, items }) => {
  const titleClassName = classnames(styles.title, {
    [styles.frontendProgram]: type === professionTypes.jsFrontend,
    [styles.backendProgram]: type === professionTypes.jsBackend,
  });

  const arrowIconClassName = classnames(styles.arrowRightIcon, {
    [styles.frontendProgram]: type === professionTypes.jsFrontend,
    [styles.backendProgram]: type === professionTypes.jsBackend,
  });

  const listItemIconClassName = classnames(styles.listItemIcon, {
    [styles.frontendListItem]: type === professionTypes.jsFrontend,
    [styles.backendListItem]: type === professionTypes.jsBackend,
  });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p className={titleClassName}>{title}</p>
        <p className={styles.duration}>{duration} months</p>
      </div>
      <div className={styles.body}>
        {type === professionTypes.jsFrontend ? (
          <JsFrontendIcon className={styles.frontendProgram} />
        ) : (
          <JsBackendIcon className={styles.backendProgram} />
        )}
        <ul>
          {items.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index}>
              <ListItemIcon className={listItemIconClassName} />
              {item}
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.footer}>
        <div>
          <WebpackIcon />
          {type === professionTypes.jsFrontend ? (
            <ReactIcon className={styles.footerIcon} />
          ) : (
            <ExpressIcon className={styles.footerIcon} />
          )}
          {type === professionTypes.jsFrontend ? (
            <BootstrapIcon className={styles.footerIcon} />
          ) : (
            <MongodbIcon className={styles.footerIcon} />
          )}
        </div>
        <div>
          <Link to={type === professionTypes.jsFrontend ? '/frontend' : '/backend'} className={styles.link} smooth>
            learn more
            <ArrowRightIcon className={arrowIconClassName} />
          </Link>
        </div>
      </div>
    </div>
  );
};

ProfessionBlock.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ProfessionBlock;
