/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Modal from './Modal';
import CloseIcon from '../icons/CloseIcon';

import styles from './ConfirmationModal.module.scss';

const ConfirmationModal = ({ state }) => {
  const { isOpen, close, apply, message } = state;
  const closeModal = (e) => {
    close();
    e.preventDefault();
  };

  return (
    <Modal isOpen={isOpen} close={close}>
      <div className={styles.container}>
        <div className={styles.header}>
          <a href="#" onClick={closeModal}>
            <CloseIcon color="#131925" opacity={0.6} />
          </a>
        </div>
        <p className={styles.message}>{message}</p>
        <div className={styles.buttons}>
          <button type="button" className={classnames(styles.button, styles.isDanger)} onClick={apply}>
            Yes
          </button>
          <button type="button" className={styles.button} onClick={closeModal}>
            No
          </button>
        </div>
      </div>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  state: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    apply: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
  }).isRequired,
};

export const useConfirmationModal = (message) => {
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState(() => {});

  return {
    message: message || 'Are you sure?',
    isOpen,
    open: (applyAction) => {
      setAction(applyAction);
      setIsOpen(true);
    },
    close: () => {
      setIsOpen(false);
    },
    apply: () => {
      setIsOpen(false);
      action();
    },
  };
};

export default ConfirmationModal;
