/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './DefaultInput.module.scss';

const DefaultInput = ({ label, type, value, onChange, placeholder, disabled, isInvalid }) => (
  <div className={styles.field}>
    {label && <label className={styles.label}>{label}</label>}
    <input
      className={classnames(styles.input, { [styles.invalidInput]: isInvalid })}
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
    />
  </div>
);

DefaultInput.defaultProps = {
  label: '',
  type: 'text',
  value: '',
  placeholder: '',
  disabled: false,
  isInvalid: false,
};

DefaultInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
};

export default DefaultInput;
