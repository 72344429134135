/* eslint-disable react/jsx-props-no-spreading */
// todo
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field } from 'formik';

import styles from './ProfileInput.module.scss';

const ProfileInput = ({ field, form, label, type, placeholder, disabled, fieldClassName }) => {
  const hasError = form.errors[field.name] && form.touched[field.name];

  return (
    <>
      <div className={classnames(styles.field, fieldClassName || '')}>
        {/* todo */}
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        {label && <label className={styles.label}>{label}</label>}
        <Field
          type={type || 'text'}
          {...field}
          className={classnames(styles.input, { [styles.invalidInput]: hasError })}
          placeholder={placeholder || ''}
          disabled={disabled || false}
        />
        {hasError && (
          <p className={classnames(styles.inputMessage, styles.invalidInputMessage)}>{form.errors[field.name]}</p>
        )}
      </div>
    </>
  );
};

ProfileInput.defaultProps = {
  disabled: false,
  isMobile: false,
  placeholder: '',
  type: 'text',
  label: '',
  fieldClassName: '',
};

ProfileInput.propTypes = {
  // todo
  // eslint-disable-next-line react/forbid-prop-types
  field: PropTypes.object.isRequired,
  // todo
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isMobile: PropTypes.bool,
  fieldClassName: PropTypes.string,
};

export default ProfileInput;
