import React from 'react';

import { ReactComponent as TasksIcon } from '../../../icons/tasks.svg';
import { ReactComponent as UsersIcon } from '../../../icons/users.svg';
import { ReactComponent as PlayIcon } from '../../../icons/play.svg';

import styles from './StartScreenFooter.module.scss';
import StartScreenFooterBadge from './StartScreenFooterBadge';

const StartScreenFooter = () => {
  return (
    <div className={styles.footer}>
      <StartScreenFooterBadge title="2000+" icon={<PlayIcon />}>
        Hours of theory and live coding
        <br />
        sessions with professionals
      </StartScreenFooterBadge>
      <StartScreenFooterBadge title="500+" icon={<TasksIcon />}>
        Various tasks and
        <br />
        different levels of tests
      </StartScreenFooterBadge>
      <StartScreenFooterBadge title="150+" icon={<UsersIcon />}>
        People completed our programs
        <br />
        and get a promotion
      </StartScreenFooterBadge>
    </div>
  );
};

export default StartScreenFooter;
