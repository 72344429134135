import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field, withFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import ProfileInput from '../../formik/ProfileInput';
import SubmitButton from '../../formik/SubmitButton';

import styles from './LoginForm.module.scss';
import Alert from '../../common/alert/Alert';
import actions from '../../../actions';

const LoginForm = ({ isSubmitting, errors }) => {
  return (
    <Form noValidate>
      {errors.summary && <Alert message={errors.summary} isError />}
      <Field
        name="username"
        component={ProfileInput}
        placeholder="Email"
        disabled={isSubmitting}
        fieldClassName={styles.field}
      />
      <Field
        name="password"
        type="password"
        component={ProfileInput}
        placeholder="Password"
        disabled={isSubmitting}
        fieldClassName={styles.field}
      />
      <SubmitButton isSubmitting={isSubmitting} title="Sign In" type="primary" isFullWidth />
    </Form>
  );
};

LoginForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    summary: PropTypes.string,
  }).isRequired,
};

const mapPropsToValues = () => ({
  username: '',
  password: '',
});

const validationSchema = yup.object().shape({
  username: yup.string().email().required(),
  password: yup.string().required(),
});

const handleSubmit = async (values, bag) => {
  let isSuccess = false;

  try {
    bag.setSubmitting(true);

    const { username, password } = values;

    const response = await axios.post('/api/v1/auth/login', { username, password });

    if (response.data.success) {
      bag.props.dispatch(actions.authSuccess(response.data.token));
      // TODO move it to service
      localStorage.setItem('token', response.data.token);
      isSuccess = true;
    } else {
      bag.setErrors({ summary: response.data.errorMessage || 'Unknown error' });
    }
  } catch (e) {
    bag.setErrors({ summary: (e.response && e.response.data && e.response.data.errorMessage) || 'UNKNOWN ERROR' });
    bag.setFieldValue('password', '', false);
  } finally {
    bag.setSubmitting(false);
  }

  if (isSuccess) {
    bag.props.history.push('/my'); // TODO to const
  }
};

// TODO why with router is here???
export default compose(
  connect(),
  withRouter,
  withFormik({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
  }),
)(LoginForm);
