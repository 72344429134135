import React from 'react';

import styles from './ProfileFooter.module.scss';

import Logo from '../common/logo/Logo';

const Footer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.footerContainer}>
          <div>
            <Logo />
            <p className={styles.footerLinkParagraph}>
              tel:{' '}
              <a href="tel:+447460143813" className={styles.footerLink}>
                +44 746 014 38 13
              </a>
            </p>
            <p className={styles.footerLinkParagraph}>
              email:{' '}
              <a href="mailto:info@reinforcement.pro" className={styles.footerLink}>
                info@reinforcement.pro
              </a>
            </p>
            <p className={styles.footerLinkParagraph}>
              web:{' '}
              <a href="http://reinforcement.pro" className={styles.footerLink}>
                http://reinforcement.pro
              </a>
            </p>
          </div>
        </div>
        <div className={styles.copyright}>
          <p>REINFORCEMENT LTD . REGISTERED UNDER THE INTERNATIONAL BUSINESS COMPANIES ACT | COMPANY NUMBER: 217470</p>
          <p>
            Registered Address: Suite 1, Second Floor, Sound & Vision House, Francis Rachel Str., Victoria, Mahe,
            Seychelles
          </p>
          <p>© 2020 Reinforcement.pro. All Rights Reserved.</p>
          {/* <p>Terms of Use | Privacy Notice | Refund Policy</p> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
