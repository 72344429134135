import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSave, faTrash, faPlus, faPen } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';

import styles from './Buttons.module.scss';

export const OutlineButton = ({ onClick, title, disabled, text, className }) => {
  return (
    <button
      type="button"
      className={classnames(styles.button, className)}
      onClick={onClick}
      title={title}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

OutlineButton.defaultProps = {
  text: '',
  title: '',
  disabled: false,
  className: '',
};

OutlineButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export const IconButton = ({ onClick, title, disabled, icon, text, className }) => {
  return (
    <button
      type="button"
      className={classnames(styles.button, className)}
      onClick={onClick}
      title={title}
      disabled={disabled}
    >
      {icon && <FontAwesomeIcon icon={icon} />}
      {text && <span>{text}</span>}
    </button>
  );
};

IconButton.defaultProps = {
  text: '',
  title: '',
  disabled: false,
  className: '',
};

IconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.object.isRequired,
  text: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export const SaveButton = ({ onClick, disabled }) => (
  <IconButton className="button is-link" text="Save" icon={faSave} onClick={onClick} disabled={disabled || false} />
);

SaveButton.defaultProps = {
  disabled: false,
};

SaveButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export const DeleteButton = ({ onClick, text, className }) => (
  <IconButton className={classnames(styles.isDanger, className)} text={text} icon={faTrash} onClick={onClick} />
);

DeleteButton.defaultProps = {
  text: '',
  className: '',
};

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
  className: PropTypes.string,
};

export const IconLink = ({ to, icon, text }) => {
  return (
    <Link className={styles.button} to={to}>
      <FontAwesomeIcon icon={icon} />
      {text && <span className="ml-10px">{text}</span>}
    </Link>
  );
};

IconLink.defaultProps = {
  text: '',
};

IconLink.propTypes = {
  to: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.object.isRequired,
  text: PropTypes.string,
};

export const BackLink = ({ to }) => <IconLink to={to} className="button is-info" text="Back" icon={faArrowLeft} />;

BackLink.propTypes = {
  to: PropTypes.string.isRequired,
};

export const AddLink = ({ text, to }) => <IconLink className="button is-link" text={text} icon={faPlus} to={to} />;

AddLink.defaultProps = {
  text: '',
};

AddLink.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export const EditLink = ({ text, to }) => <IconLink className="button is-link" text={text} icon={faPen} to={to} />;

EditLink.defaultProps = {
  text: '',
};

EditLink.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string,
};
