import { useState, useEffect } from 'react';
import update from 'immutability-helper';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import axios from 'axios';

const defaultWebinar = {
  name: { value: '', isValid: false },
  description: '',
  date: new Date(),
  price: { value: 0, valueStr: '0', isValid: false },
};

const isWebinarValid = (webinar) => {
  return webinar.name.isValid && webinar.price.isValid;
};

const useAdminEditWebinarPageState = (webinarId, history) => {
  const { token } = useSelector((state) => state);
  const [webinar, setWebinar] = useState(defaultWebinar);
  const [status, setStatus] = useState({ isLoading: false, isError: false, canSave: isWebinarValid(defaultWebinar) });

  const isEditMode = !_.isUndefined(webinarId);

  useEffect(() => {
    if (isEditMode) {
      setStatus(update(status, { isLoading: { $set: true }, isError: { $set: false } }));
      axios
        .get(`/api/v1/admin/webinars/${webinarId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const data = response.data.webinar;
          const loadedWebinar = {
            ...data,
            date: new Date(data.date),
            name: { value: data.name, isValid: true },
            price: { value: data.price, valueStr: `${data.price}`, isValid: true },
          };
          setWebinar(loadedWebinar);
          setStatus(
            update(status, {
              isLoading: { $set: false },
              isError: { $set: false },
              canSave: { $set: isWebinarValid(loadedWebinar) },
            }),
          );
        })
        .catch(() => {
          setStatus(update(status, { isLoading: { $set: false }, isError: { $set: true } }));
        });
    }
  }, []);

  const changeName = (e) => {
    const newWebinar = update(webinar, {
      name: { value: { $set: e.target.value }, isValid: { $set: e.target.value.trim() !== '' } },
    });

    setWebinar(newWebinar);
    setStatus(update(status, { canSave: { $set: isWebinarValid(newWebinar) } }));
  };

  const changeDescription = (e) => {
    const newWebinar = update(webinar, {
      description: { $set: e.target.value },
    });

    setWebinar(newWebinar);
  };

  const chnageDate = (date) => {
    setWebinar(update(webinar, { date: { $set: date } }));
  };

  const changePrice = (e) => {
    const value = _.toNumber(e.target.value);

    const isValid = !_.isNaN(value) && _.isInteger(value);
    const newValue = isValid ? value : webinar.price;

    const newWebinar = update(webinar, {
      price: {
        value: { $set: newValue },
        valueStr: { $set: e.target.value },
        isValid: { $set: isValid && newValue > 0 },
      },
    });

    setWebinar(newWebinar);
    setStatus(update(status, { canSave: { $set: isWebinarValid(newWebinar) } }));
  };

  const saveData = () => {
    const webinarData = { ...webinar, name: webinar.name.value, price: webinar.price.value };

    setStatus(update(status, { isLoading: { $set: true }, isError: { $set: false } }));

    if (isEditMode) {
      axios
        .put(`/api/v1/admin/webinars/${webinarId}`, webinarData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          setStatus(update(status, { isLoading: { $set: false }, isError: { $set: false } }));
          history.push('/admin/webinars');
        })
        .catch(() => {
          setStatus(update(status, { isLoading: { $set: false }, isError: { $set: true } }));
        });
    } else {
      axios
        .post('/api/v1/admin/webinars', webinarData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          setStatus(update(status, { isLoading: { $set: false }, isError: { $set: false } }));
          history.push('/admin/webinars');
        })
        .catch(() => {
          setStatus(update(status, { isLoading: { $set: false }, isError: { $set: true } }));
        });
    }
  };

  return {
    webinar,
    status,
    actions: {
      changeName,
      changeDescription,
      chnageDate,
      changePrice,
      saveData,
    },
  };
};

export default useAdminEditWebinarPageState;
