/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CaretDownIcon } from '../../icons/caret-down.svg';
import { ReactComponent as CaretUpIcon } from '../../icons/caret-up.svg';

import styles from './PaymentsTable.module.scss';

const getPaymentStatusInfo = (status) => {
  switch (status) {
    case 1:
      return ['#D32F2F', 'UNPAID'];
    case 2:
      return ['orange', 'PENDING'];
    case 3:
      return ['#17C671', 'PAID'];
    default:
      return ['black', 'UNKNOWN'];
  }
};

const PaymentsTable = ({ payments }) => {
  const [openIndex, setOpenIndex] = useState(-1);

  const toggleRowOpen = (index) => () => {
    setOpenIndex(openIndex === index ? -1 : index);
  };

  return (
    <div className={styles.profilePaymentsContainer}>
      <p className={styles.profileTitle}>Payments history</p>
      {payments.length === 0 && <p className={styles.profilePaymentsEmpty}>You do not have any payments history yet</p>}
      {payments.length > 0 && (
        <table className={styles.paymentsTable}>
          <thead>
            <tr>
              <th className={styles.hiddenSm} />
              <th>Date</th>
              <th>Client Name</th>
              <th>Total</th>
              <th>Status</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {payments.map((payment, i) => {
              const [statusColor, statusText] = getPaymentStatusInfo(3);
              return (
                <React.Fragment key={i}>
                  <tr className={styles.trHover} onClick={toggleRowOpen(i)}>
                    <td className={styles.hiddenSm}>{i + 1}</td>
                    <td>{payment.date}</td>
                    <td>{payment.client.name}</td>
                    <td className={styles.bold}>{payment.total} €</td>
                    <td className={styles.vCenter}>
                      <div className={styles.status}>
                        <div
                          className={styles.paymentStatusIcon}
                          style={{ background: statusColor, marginTop: '0px' }}
                        />
                        <p className={styles.hiddenSm}>{statusText}</p>
                      </div>
                    </td>
                    <td>{openIndex === i ? <CaretUpIcon /> : <CaretDownIcon />}</td>
                  </tr>
                  {openIndex === i && (
                    <tr>
                      <td colSpan="6">
                        <p>
                          Client Name: <strong>{payment.client.name}</strong>
                        </p>
                        <p>
                          Client Address: <strong>{payment.client.address}</strong>
                        </p>
                        <table className="table is-bordered is-fullwidth is-small mt-20px">
                          <thead>
                            <tr>
                              <th className="cell-center" style={{ width: '25px' }} />
                              <th className="cell-center" style={{ width: '50px' }}>
                                Qty
                              </th>
                              <th className="cell-center" style={{ width: '50px' }}>
                                Price
                              </th>
                              <th className="cell-center" style={{ width: '50px' }}>
                                Total
                              </th>
                              <th className="cell-center" style={{ width: '50px' }}>
                                Rate
                              </th>
                              <th className={styles.hiddenSm}>Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            {payment.items.map((item, itemIndex) => (
                              <tr key={itemIndex}>
                                <td className="cell-center">{itemIndex + 1}</td>
                                <td className="cell-center">{item.count}</td>
                                <td className="cell-center">{item.price}</td>
                                <td className="cell-center">{item.total}</td>
                                <td className="cell-center">{item.rate}</td>
                                <td className={styles.hiddenSm}>{item.description}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

PaymentsTable.propTypes = {
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      total: PropTypes.number.isRequired,
      client: PropTypes.shape({
        name: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
      }).isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          count: PropTypes.number.isRequired,
          description: PropTypes.string.isRequired,
          price: PropTypes.number.isRequired,
          rate: PropTypes.number.isRequired,
        }),
      ),
    }),
  ).isRequired,
};

export default PaymentsTable;
