import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { HashLink } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';
import Sidebar from 'react-sidebar';

import styles from './ProfessionPageTemplate.module.scss';
import Header from '../common/header/Header';
import LinkButton from '../common/link/LinkButton';
import { ReactComponent as ListItemIcon } from '../../icons/list-item.svg';
import AboutBlock from './AboutBlock';

import ProgramBlock from './ProgramBlock';
import Logo from '../common/logo/Logo';
import { professionTypes } from '../../utils/utils';
import EnrollProfessionForm from './EnrollProfessionForm';
import NotificationModal from '../common/modals/NotificationModal';
import { getDesktopSidebarStyles } from '../../utils/sidebar-utils';
import useSidebar from '../common/sidebar/useSidebar';
import ProgramPageSidebarContent from '../common/sidebar/ProgramPageSidebarContent';

const getCurrentPagePath = (pathname, containerId) => `${pathname}#${containerId}`;

const ProfessionPageTemplate = ({ data }) => {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationData, setNotificationData] = useState({ type: '', header: '', text: '' });

  const closeNotification = () => {
    setIsNotificationOpen(false);
  };

  const openNotification = (type) => {
    setIsNotificationOpen(true);
    setNotificationData(type);
  };

  const listItemIconClassName = classnames(styles.listItemIcon, {
    [styles.frontendListItem]: data.type === professionTypes.jsFrontend,
    [styles.backendListItem]: data.type === professionTypes.jsBackend,
  });

  const startScreenClassNames = classnames(styles.startScreen, {
    [styles.frontendScreen]: data.type === professionTypes.jsFrontend,
    [styles.backendScreen]: data.type === professionTypes.jsBackend,
  });

  const pricingScreenClassNames = classnames(styles.pricingContainer, {
    [styles.frontendPricingContainer]: data.type === professionTypes.jsFrontend,
    [styles.backendPricingContainer]: data.type === professionTypes.jsBackend,
  });

  const location = useLocation();

  const { isSidebarOpen, setIsSidebarOpen, openSidebar, closeSidebar } = useSidebar();

  return (
    <Sidebar
      open={isSidebarOpen}
      onSetOpen={setIsSidebarOpen}
      sidebar={<ProgramPageSidebarContent closeSidebar={closeSidebar} type={data.type} />}
      styles={getDesktopSidebarStyles()}
    >
      <div className={styles.wrapper} id="page-top-id">
        <div className={startScreenClassNames}>
          <Header type={data.type} openSidebar={openSidebar} />
          <div className={classnames(styles.startScreenContent, styles.container)}>
            <div>
              <div className={styles.professionLabel}>profession</div>
              <div className={styles.professionName}>{data.name}</div>
              <ul>
                {data.agenda.map((x, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={index}>
                    <ListItemIcon className={listItemIconClassName} />
                    {x}
                  </li>
                ))}
              </ul>
              <LinkButton title="Sign Up" to="/" type={data.type} isFixed width={250} height={50} />
            </div>
          </div>
        </div>
        <div className={styles.aboutContainer} id="page-about-id">
          <div className={styles.container}>
            <p className={styles.blockTitle}>what do you learn in thIS course</p>
            {data.description.paragraphs.map((x) => (
              <p className={styles.text}>{x}</p>
            ))}
            <div className={styles.aboutBlocksContainer}>
              {data.description.blocks.map(({ icon, title, text }) => (
                <AboutBlock icon={icon} text={text} title={title} type={data.type} />
              ))}
            </div>
          </div>
        </div>
        <div className={styles.programContainer} id="page-program-id">
          <div className={styles.container}>
            <div className={styles.blockTitle}>Training program</div>
            <div className={styles.programsBlock}>
              {data.program.map(({ name, lessons, theoryCount, practiceCount }, i) => (
                <ProgramBlock
                  title={name}
                  practiceCount={practiceCount}
                  blockIndex={i}
                  theoryCount={theoryCount}
                  lessons={lessons}
                  type={data.type}
                />
              ))}
            </div>
          </div>
        </div>
        <div className={pricingScreenClassNames} id="page-pricing-id">
          <div className={styles.container}>
            <div className={styles.blockTitle}>Pricing</div>
            <div className={styles.pricingContent}>
              <div className={styles.pricingContentColumn}>
                <p className={styles.text}>Buy today our course of {data.name} and get a discount</p>
                <p className={styles.priceText}>price for today</p>
                <p className={styles.price}>{data.price.current} €</p>
                <p className={styles.oldPrice}>{data.price.old} €</p>
              </div>
              <div className={styles.pricingContentColumn}>
                <div className={styles.requestBlock}>
                  <div
                    className={classnames(styles.saleBlock, {
                      [styles.saleBlockBackend]: data.type === professionTypes.jsBackend,
                    })}
                  >
                    <div className={styles.saleText}>-{data.discount.percent} %</div>
                    <div>
                      for the first
                      <br />
                      {data.discount.studentsCount} students
                    </div>
                  </div>
                  <p>Send request for {data.name} or take a free consultation</p>
                  <EnrollProfessionForm type={data.type} showNotification={openNotification} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.container}>
            <div className={styles.footerContainer}>
              <div>
                <Logo />
                <p className={styles.footerLinkParagraph}>
                  tel:{' '}
                  <a href="tel:+447460143813" className={styles.footerLink}>
                    +44 746 014 38 13
                  </a>
                </p>
                <p className={styles.footerLinkParagraph}>
                  email:{' '}
                  <a href="mailto:info@reinforcement.pro" className={styles.footerLink}>
                    info@reinforcement.pro
                  </a>
                </p>
                <p className={styles.footerLinkParagraph}>
                  web:{' '}
                  <a href="http://reinforcement.pro" className={styles.footerLink}>
                    http://reinforcement.pro
                  </a>
                </p>
              </div>
              <div className={styles.footerMenu}>
                <p>
                  <HashLink
                    to={getCurrentPagePath(location.pathname, 'page-top-id')}
                    className={styles.footerMenuLink}
                    smooth
                  >
                    Home
                  </HashLink>
                </p>
                <p>
                  <HashLink
                    to={getCurrentPagePath(location.pathname, 'page-about-id')}
                    className={styles.footerMenuLink}
                    smooth
                  >
                    About
                  </HashLink>
                </p>
                <p>
                  <HashLink
                    to={getCurrentPagePath(location.pathname, 'page-program-id')}
                    className={styles.footerMenuLink}
                    smooth
                  >
                    Program
                  </HashLink>
                </p>
                <p>
                  <HashLink
                    to={getCurrentPagePath(location.pathname, 'page-pricing-id')}
                    className={styles.footerMenuLink}
                    smooth
                  >
                    Pricing
                  </HashLink>
                </p>
              </div>
              <LinkButton title="Start learning" to="/" type={data.type} isFixed width={200} height={50} />
            </div>
            <div className={styles.copyright}>
              <p>
                REINFORCEMENT LTD. REGISTERED UNDER THE INTERNATIONAL BUSINESS COMPANIES ACT | COMPANY NUMBER: 217470
              </p>
              <p>
                Registered Address: Suite 1, Second Floor, Sound & Vision House, Francis Rachel Str., Victoria, Mahe,
                Seychelles
              </p>
              <p>© 2020 Reinforcement.pro. All Rights Reserved.</p>
              {/* <p>Terms of Use | Privacy Notice | Refund Policy</p> */}
            </div>
          </div>
        </div>
      </div>
      <NotificationModal isOpen={isNotificationOpen} close={closeNotification} data={notificationData} />
    </Sidebar>
  );
};

ProfessionPageTemplate.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    agenda: PropTypes.arrayOf(PropTypes.string).isRequired,
    description: PropTypes.shape({
      paragraphs: PropTypes.arrayOf(PropTypes.string).isRequired,
      blocks: PropTypes.arrayOf(
        PropTypes.shape({
          icon: PropTypes.element.isRequired,
          title: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
    program: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        theoryCount: PropTypes.number.isRequired,
        practiceCount: PropTypes.number.isRequired,
        lessons: PropTypes.arrayOf(PropTypes.string).isRequired,
      }),
    ).isRequired,
    price: PropTypes.shape({
      current: PropTypes.number.isRequired,
      old: PropTypes.number.isRequired,
    }).isRequired,
    discount: PropTypes.shape({
      percent: PropTypes.number.isRequired,
      studentsCount: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ProfessionPageTemplate;
