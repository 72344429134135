import React from 'react';
import ProfessionPageTemplate from '../components/profession/ProfessionPageTemplate';
import { ReactComponent as FrontendAboutIcon1 } from '../icons/frontend-about-1.svg';
import { ReactComponent as FrontendAboutIcon2 } from '../icons/frontend-about-2.svg';
import { ReactComponent as FrontendAboutIcon3 } from '../icons/frontend-about-3.svg';
import { professionTypes } from '../utils/utils';

const data = {
  type: professionTypes.jsFrontend,
  name: 'Javascript frontend developer',
  agenda: ['Learn JavaScript basics', 'Understand how to use React.js', 'Create your first website'],
  description: {
    paragraphs: [
      'JavaScript is one of the most popular programming language in the world. It is used for creating modern and fast web applications',
      'You will learn how to use the language for creating interactive websites using one of the most popular framework',
      'You will learn JavaScript through the practice. Our program includes many hours of practical lessons',
      'At the end of the course you will create you first website that you can use for your portfolio',
    ],
    blocks: [
      {
        icon: <FrontendAboutIcon1 />,
        title: 'Modern JS',
        text:
          'Use all modern features from ES standard. Learn object oriented programming basics, functional programming basics and design patterns',
      },
      {
        icon: <FrontendAboutIcon2 />,
        title: 'React and Redux',
        text:
          'Learn React, Redux and architecture of frontend applications. Learn how to create a website from scratch',
      },
      {
        icon: <FrontendAboutIcon3 />,
        title: 'Debug and Test',
        text:
          'Learn how to use features for testing frontend applications and how to deploy it. Learn how to write your own tests',
      },
    ],
  },
  program: [
    {
      name: 'Introduction to programming',
      theoryCount: 4,
      practiceCount: 1,
      lessons: ['What is the programming?', 'Who is a programmer?', 'Programmer career', 'Programming companies types'],
    },
    {
      name: 'Introduction to JavaScript',
      theoryCount: 24,
      practiceCount: 15,
      lessons: [
        'Introduction',
        'History of JavaScript',
        'First Program',
        'JavaScript data types',
        'Variables',
        'Conditionals',
        'Loops',
        'Functions',
        'Arrays',
        'Scope',
        'Closures',
        'Iterators',
        'Objects',
        'Classes',
      ],
    },
    {
      name: 'GIT Basics',
      theoryCount: 18,
      practiceCount: 4,
      lessons: [
        'Introduction',
        'What is version control system?',
        'GIT basics',
        'Git Fundamentals',
        'GIT Branches',
        'GIT Distributed',
        'Introduction to GitHub',
        'GitHub basics',
      ],
    },
    {
      name: 'Command line basics',
      theoryCount: 17,
      practiceCount: 3,
      lessons: [
        'Introduction',
        'Operation Systems',
        'Command Line Interface',
        'Command Line Navigation',
        'File Structure',
        'Operations with Files and Folders',
        'Pipelines',
        'Environment Variables',
        'User and Groups',
        'Package Manager',
        'Program Execution',
      ],
    },
    {
      name: 'Introduction to web development',
      theoryCount: 10,
      practiceCount: 3,
      lessons: [
        'Introduction',
        'What is HTML?',
        'What is CSS?',
        'What is Scripts?',
        'Hosting Providers',
        'Frontend vs Backend',
      ],
    },
    {
      name: 'Project setup',
      theoryCount: 15,
      practiceCount: 2,
      lessons: [
        'Introduction',
        'NPM',
        'Dependencies',
        'Lock File',
        'Scripts',
        'Publishing',
        'Executable vs Libraries',
        'Versioning',
        'NPX',
      ],
    },
    {
      name: 'JavaScript arrays',
      theoryCount: 48,
      practiceCount: 15,
      lessons: [
        'Introduction',
        'Array basics',
        'for Loop',
        'for...of Loop',
        'References',
        'Mutable vs Immutable',
        'Jagged Arrays',
        'break, continue',
        'Strings and Arrays',
        'Stack',
        'Queue',
        'Destructuring',
        'Rest operator',
        'Spread operator',
        'Memory and Complexity',
      ],
    },
    {
      name: 'JavaScript objects',
      theoryCount: 16,
      practiceCount: 9,
      lessons: [
        'Introduction',
        'Object basics',
        'Map vs Object',
        'Arrays vs Object',
        'Work with Object Properties',
        'Destructuring',
        'Spread operator',
      ],
    },
    {
      name: 'JavaScript functions',
      theoryCount: 14,
      practiceCount: 10,
      lessons: [
        'Introduction',
        'Basics',
        'High Order Functions',
        'Pure Functions',
        'Rest operator',
        'Spread operator',
        'Functions vs Objects',
        'Programming with Functions',
      ],
    },
    {
      name: 'How to test JS applications',
      theoryCount: 20,
      practiceCount: 6,
      lessons: [
        'Introduction',
        'What is Tests?',
        'Test Configuration with Jest',
        'First Test with Jest',
        'Jest Matchers',
        'Unit Test',
        'Integration Tests',
        'Code Coverage',
        'Test Driven Development',
        'Continuous Integration',
        'Best Practices of Testing',
      ],
    },
    {
      name: 'JavaScript asynchronous programming',
      theoryCount: 22,
      practiceCount: 16,
      lessons: [
        'Introduction',
        'Call Stack',
        'First async Example',
        'Timers',
        'Parallel Execution',
        'Event Loop',
        'Callbacks',
        'Promises',
        'Error Handling',
        'Async/Await',
      ],
    },
    {
      name: 'JavaScript DOM API',
      theoryCount: 24,
      practiceCount: 20,
      lessons: [
        'Introduction',
        'What is DOM?',
        'DOM tree',
        'Work with Development Tools',
        'Window Object',
        'DOM Manipulations',
        'Polyfills',
        'Events',
        'Forms',
        'AJAX',
      ],
    },
    {
      name: 'JavaScript architecture',
      theoryCount: 36,
      practiceCount: 7,
      lessons: [
        'Introduction',
        'Processes',
        'Program State',
        'Forms State',
        'MVC',
        'Typical Architecture',
        'Framework-Based Architecture',
        'Single Page Applications',
        'Multipage Applications',
        'Isomorphic (Universal) Applications',
      ],
    },
    {
      name: 'Introduction to React',
      theoryCount: 47,
      practiceCount: 21,
      lessons: [
        'Introduction',
        'JSX',
        'Components and Props',
        'Rendering',
        'Component Lifecycle',
        'Component State',
        'Event Handling',
        'Lists and Keys',
        'Forms',
        'Composition vs Inheritance',
        'Thinking in React',
      ],
    },
    {
      name: 'Advanced React',
      theoryCount: 56,
      practiceCount: 20,
      lessons: [
        'Introduction',
        'JSX in Depth',
        'Context',
        'Hooks',
        'Prop Types',
        'Code Splitting',
        'Refs',
        'Fragments',
        'High-Order Components',
        'Portals and Modals',
        'Routing',
        'Optimizing Performance',
        'Profiler',
      ],
    },
    {
      name: 'Introduction to Redux',
      theoryCount: 26,
      practiceCount: 19,
      lessons: ['Introduction', 'Actions', 'Reducers', 'Store', 'Redux Flow', 'Using with React'],
    },
    {
      name: 'Advanced Redux',
      theoryCount: 40,
      practiceCount: 18,
      lessons: [
        'Introduction',
        'Async Actions',
        'Async Flow',
        'Middleware',
        'Using with React Router',
        'Recipes and Best Practices',
      ],
    },
    {
      name: 'Advanced testing',
      theoryCount: 44,
      practiceCount: 15,
      lessons: [
        'Introduction',
        'Error Testing',
        'Fixtures',
        'Side Effects',
        'Integration Testing',
        'HTTP Requests Testing',
        'React Testing',
        'Redux Testing',
      ],
    },
    {
      name: 'Create your first app',
      theoryCount: 2,
      practiceCount: 100,
      lessons: ['Introduction', 'Create your first React&Redux App'],
    },
  ],
  price: {
    current: 2999,
    old: 6000,
  },
  discount: {
    percent: 21,
    studentsCount: 19,
  },
};

const FrontendPage = () => {
  return <ProfessionPageTemplate data={data} />;
};

export default FrontendPage;
