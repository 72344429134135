import React from 'react';
import Sidebar from 'react-sidebar';

import StartScreen from '../components/landing/StartScreen';
import AboutScreen from '../components/landing/AboutScreen';
import WhoNeedsScreen from '../components/landing/WhoNeedsScreen';
import ProgramsScreen from '../components/landing/ProgramsScreen';
import ContactScreen from '../components/landing/ContactScreen';
import Footer from '../components/landing/Footer';
import useSidebar from '../components/common/sidebar/useSidebar';
import SidebarContent from '../components/common/sidebar/SidebarContent';
import { getDesktopSidebarStyles } from '../utils/sidebar-utils';

const MainPage = () => {
  const { isSidebarOpen, setIsSidebarOpen, openSidebar, closeSidebar } = useSidebar();

  return (
    <Sidebar
      open={isSidebarOpen}
      onSetOpen={setIsSidebarOpen}
      sidebar={<SidebarContent closeSidebar={closeSidebar} />}
      styles={getDesktopSidebarStyles()}
    >
      <StartScreen openSidebar={openSidebar} />
      <AboutScreen />
      <WhoNeedsScreen />
      <ProgramsScreen />
      <ContactScreen />
      <Footer />
    </Sidebar>
  );
};

export default MainPage;
