import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field, withFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

import ProfileInput from '../../formik/ProfileInput';

import styles from './RegisterForm.module.scss';
import SubmitButton from '../../formik/SubmitButton';
import Alert from '../../common/alert/Alert';

const RegisterForm = ({ isSubmitting, errors }) => {
  return (
    <Form noValidate>
      {errors.summary && <Alert message={errors.summary} isError />}
      <Field
        name="name"
        component={ProfileInput}
        placeholder="Name"
        disabled={isSubmitting}
        fieldClassName={styles.field}
      />
      <Field
        name="email"
        type="email"
        component={ProfileInput}
        placeholder="Email"
        disabled={isSubmitting}
        fieldClassName={styles.field}
      />
      <Field
        name="password"
        type="password"
        component={ProfileInput}
        placeholder="Password"
        disabled={isSubmitting}
        fieldClassName={styles.field}
      />
      <Field
        name="confirmPassword"
        type="password"
        component={ProfileInput}
        placeholder="Confirm password"
        disabled={isSubmitting}
        fieldClassName={styles.field}
      />
      <SubmitButton isSubmitting={isSubmitting} title="Register" type="primary" isFullWidth />
    </Form>
  );
};

RegisterForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    summary: PropTypes.string,
  }).isRequired,
};

const mapPropsToValues = () => ({
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
});

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().required(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required(),
});

const handleSubmit = async (props, bag) => {
  let isSuccess = false;

  bag.setSubmitting(true);
  // TODO send only selected props (without confirmation???)
  try {
    const { data } = await axios.post('/api/v1/auth/register', props);

    if (data.success) {
      isSuccess = true;
    } else {
      bag.setErrors({ summary: data.errorMessage });
    }
  } catch (e) {
    bag.setErrors({ summary: e.response.data.errorMessage });
  }

  bag.setSubmitting(false);

  if (isSuccess) {
    bag.props.history.push('/login');
  }
};

export default withRouter(
  withFormik({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
  })(RegisterForm),
);
