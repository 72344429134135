import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './AdminPageLink.module.scss';

const AdminPageLink = ({ to, text }) => (
  <Link to={to} className={styles.link}>
    {text}
  </Link>
);

AdminPageLink.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default AdminPageLink;
