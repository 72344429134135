import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Logo.module.scss';

import { ReactComponent as LogoSvg } from '../../../icons/logo.svg';

const Logo = () => {
  return (
    <Link to="/" className={styles.link}>
      <LogoSvg />
    </Link>
  );
};

export default Logo;
