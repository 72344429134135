import React from 'react';
import PropTypes from 'prop-types';

import AdminPageContainer from '../common/AdminPageContainer';
import AdminPageTitle from '../common/AdminPageTitle';
import Loader from '../../common/loader/Loader';
import ErrorMessage from '../common/AdminErrorMessage';
import ConfirmationModal from '../../common/modals/ConfirmationModal';
import { AddLink, EditLink, DeleteButton } from '../common/Buttons';

import useAdminWebinarsPageState from './useAdminWebinarsPageState';

import styles from './AdminWebinarsPage.module.scss';

const AdminWebinarsPage = ({ history }) => {
  const { status, data, deleteConfirmation, openDeleteConfirmation } = useAdminWebinarsPageState(history);

  return (
    <AdminPageContainer>
      <AdminPageTitle title="Webinars" />

      <Loader isVisible={status.isLoading} />

      <ErrorMessage isVisible={status.isError && !status.isLoading} />

      <ConfirmationModal state={deleteConfirmation} />

      {!status.isLoading && !status.isError && (
        <div className={styles.container}>
          <AddLink to="/admin/webinars/add" text="New Webinar" className="my-10px" />

          {data.length === 0 && <p className="admin-empty-message">No Webinars Found</p>}

          {data.map((webinar) => {
            return (
              <div key={webinar._id} className={styles.row}>
                <div>
                  <p className={styles.title}>{webinar.name}</p>
                  <p>
                    Description: <strong>{webinar.description}</strong>
                  </p>
                  <p>
                    Price: <strong>{webinar.price}€</strong>
                  </p>
                  <p>
                    Date: <strong>{webinar.date}</strong>
                  </p>
                  <p className={styles.small}>
                    id: <strong>{webinar._id}</strong>
                  </p>
                </div>
                <div className={styles.actions}>
                  <EditLink to={`/admin/webinars/${webinar._id}/edit`} />
                  <DeleteButton onClick={openDeleteConfirmation(webinar._id)} className={styles.ml10} />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </AdminPageContainer>
  );
};

AdminWebinarsPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

export default AdminWebinarsPage;
