import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Ellipsis } from 'react-spinners-css';

import styles from '../common/link/LinkButton.module.scss';
import { professionTypes } from '../../utils/utils';

const SubmitButton = ({ isSubmitting, title, isFixed, isFullWidth, height, width, type, marginTop, marginBottom }) => {
  const btnClassName = classnames(styles.btn, {
    [styles.btnPrimary]: type === 'primary',
    [styles.btnFrontend]: type === professionTypes.jsFrontend,
    [styles.btnBackend]: type === professionTypes.jsBackend,
    [styles.btnFixed]: isFixed,
  });

  let btnStyle = {
    height: `${height}px`,
    marginTop: `${marginTop}px`,
    marginBottom: `${marginBottom}px`,
  };

  if (isFixed) {
    btnStyle = { ...btnStyle, width: `${width}px`, minWidth: `${width}px` };
  }

  if (isFullWidth) {
    btnStyle = { ...btnStyle, width: `100%` };
  }

  return (
    <button type="submit" className={btnClassName} style={btnStyle} disabled={isSubmitting}>
      {!isSubmitting ? title : <Ellipsis color="white" size={40} />}
    </button>
  );
};

SubmitButton.defaultProps = {
  type: 'default',
  isFixed: false,
  width: 100,
  height: 50,
  isFullWidth: false,
  marginTop: 0,
  marginBottom: 0,
};

SubmitButton.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  isFixed: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  isFullWidth: PropTypes.bool,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
};

export default SubmitButton;
