const root = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  overflow: 'hidden',
};

const content = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  overflowY: 'auto',
  WebkitOverflowScrolling: 'touch',
  transition: 'left .3s ease-out, right .3s ease-out',
};

const overlay = {
  zIndex: 2,
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  opacity: 0,
  visibility: 'hidden',
  transition: 'opacity .3s ease-out, visibility .3s ease-out',
  backgroundColor: 'rgba(0,0,0,.75)',
};

const dragHandle = {
  zIndex: 2,
  position: 'fixed',
  top: 0,
  bottom: 0,
};

const sidebarCommon = {
  zIndex: 3,
  position: 'absolute',
  top: 0,
  bottom: 0,
  transition: 'transform .3s ease-out',
  WebkitTransition: '-webkit-transform .3s ease-out',
  willChange: 'transform',
  overflowY: 'auto',
  width: '300px',
  paddingTop: '35px',
  background: '#1E1F27',
};

export const getMobileSidebarStyles = () => {
  return {
    root,
    sidebar: {
      ...sidebarCommon,
      width: '80%',
    },
    content,
    overlay,
    dragHandle,
  };
};

export const getDesktopSidebarStyles = () => {
  return {
    root,
    sidebar: sidebarCommon,
    content,
    overlay,
    dragHandle,
  };
};
