import React from 'react';
import PropTypes from 'prop-types';

import styles from './AboutScreenInfoBlock.module.scss';

const AboutScreenInfoBlock = ({ icon, title, children }) => {
  return (
    <div className={styles.container}>
      {icon}
      <div>
        <p className={styles.title}>{title}</p>
        <p>{children}</p>
      </div>
    </div>
  );
};

AboutScreenInfoBlock.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default AboutScreenInfoBlock;
