import React from 'react';
import AuthLayoutHeader from '../components/common/auth/AuthLayoutHeader';
import AuthLayout from '../components/layouts/AuthLayout';
import RegisterForm from '../components/auth/register-form/RegisterForm';

const RegisterPage = () => {
  return (
    <AuthLayout>
      <AuthLayoutHeader isRegister />
      <RegisterForm />
    </AuthLayout>
  );
};

export default RegisterPage;
