import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field, withFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import ProfileInput from '../../formik/ProfileInput';
import SubmitButton from '../../formik/SubmitButton';

import styles from './ForgotPassword.module.scss';
import Alert from '../../common/alert/Alert';

const ForgotPasswordForm = ({ isSubmitting, status }) => {
  return (
    <Form noValidate>
      {status && status.isFail === false && <Alert message="Message has been sent" isSuccess />}
      {status && status.isFail === true && <Alert message="An unknown error occurred" isError />}
      <Field
        name="username"
        component={ProfileInput}
        placeholder="Email"
        disabled={isSubmitting}
        fieldClassName={styles.field}
      />
      <SubmitButton isSubmitting={isSubmitting} title="Send" type="primary" isFullWidth />
    </Form>
  );
};

ForgotPasswordForm.defaultProps = {
  status: { isFail: null },
};

ForgotPasswordForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    summary: PropTypes.string,
  }).isRequired,
  status: PropTypes.shape({
    isFail: PropTypes.bool,
  }),
};

const mapPropsToValues = () => ({
  username: '',
});

const validationSchema = yup.object().shape({
  username: yup.string().email().required(),
});

const handleSubmit = async (values, bag) => {
  bag.setSubmitting(true);
  try {
    const response = await axios.post('/api/v1/auth/send-reset-password-message', { username: values.username });

    if (response.data.success) {
      bag.setStatus({ isFail: false });
    } else {
      bag.setStatus({ isFail: true, errorMessage: response.data.errorMessage });
    }
  } catch (e) {
    bag.setStatus({ isFail: true, errorMessage: e.response.data.errorMessage });
  }

  bag.setSubmitting(false);
};

// TODO why with router is here???
export default withFormik({
  mapPropsToValues,
  validationSchema,
  handleSubmit,
})(ForgotPasswordForm);
