import React from 'react';
import PropTypes from 'prop-types';
import Alert from '../../common/alert/Alert';

const AdminErrorMessage = ({ message, isVisible }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div>
      <Alert message={message} isError />
    </div>
  );
};

AdminErrorMessage.defaultProps = {
  isVisible: false,
  message: 'An error occurred',
};

AdminErrorMessage.propTypes = {
  isVisible: PropTypes.bool,
  message: PropTypes.string,
};

export default AdminErrorMessage;
