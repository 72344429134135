/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const AdminRoute = ({ component: Component, isAuthenticated, user, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (isAuthenticated && !!user.isAdmin ? <Component {...props} /> : <Redirect to="/" />)}
    />
  );
};

const mapStateToProps = (state) => state;

AdminRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  user: PropTypes.shape({
    isAdmin: PropTypes.bool,
  }),
};

export default connect(mapStateToProps)(AdminRoute);
