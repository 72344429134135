import React from 'react';
import PropTypes from 'prop-types';

import AdminPageContainer from '../common/AdminPageContainer';
import AdminPageTitle from '../common/AdminPageTitle';
import AdminErrorMessage from '../common/AdminErrorMessage';
import Loader from '../../common/loader/Loader';
import ConfirmationModal from '../../common/modals/ConfirmationModal';
import { DeleteButton } from '../common/Buttons';

import useAdminRequestsPageState from './useAdminRequestsPageState';

import styles from './AdminRequestsPage.module.scss';

const AdminRequestsPage = ({ history }) => {
  const { status, data, openDeleteConfirmation, deleteConfirmation } = useAdminRequestsPageState(history);

  return (
    <AdminPageContainer>
      <AdminPageTitle title="Requests sent from landing page" />

      <Loader isVisible={status.isLoading} />

      <AdminErrorMessage isVisible={status.isError && !status.isLoading} />

      <ConfirmationModal state={deleteConfirmation} />

      {!status.isError && !status.isLoading && (
        <div className={styles.container}>
          {data.map((request) => (
            <div key={request._id} className={styles.row}>
              <div>
                <p className={styles.title}>{request.email}</p>
                <p>
                  Name: <strong>{request.name}</strong>; Phone: <strong>{request.phone}</strong>
                </p>
                <p className={styles.small}>
                  at: <strong>{request.createdAt.toString()}</strong>; id: <strong>{request._id}</strong>
                </p>
              </div>
              <DeleteButton onClick={openDeleteConfirmation(request._id)} />
            </div>
          ))}
        </div>
      )}
    </AdminPageContainer>
  );
};

AdminRequestsPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

export default AdminRequestsPage;
