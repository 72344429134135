import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import AdminPageContainer from '../../common/AdminPageContainer';
import AdminPageTitle from '../../common/AdminPageTitle';
import { AddLink, EditLink, DeleteButton } from '../../common/Buttons';
import Loader from '../../../common/loader/Loader';
import ErrorMessage from '../../common/AdminErrorMessage';
import ConfirmationModal from '../../../common/modals/ConfirmationModal';

import useAdminPaymentsPageState from './useAdminPaymentsPageState';

import styles from './AdminPaymentsPage.module.scss';

const AdminPaymentsPage = ({ match }) => {
  const { userId } = match.params;

  const {
    userName,
    isDataLoaded,
    status,
    payments,
    deleteConfirmation,
    openDeleteConfirmation,
  } = useAdminPaymentsPageState(userId);

  return (
    <AdminPageContainer>
      <AdminPageTitle to="/admin/users" title={`Payments for ${userName}`} />

      <Loader isVisible={status.isLoading} />
      <ErrorMessage isVisible={status.isError} />
      <ConfirmationModal state={deleteConfirmation} />

      <AddLink to={`/admin/users/${userId}/payments/add`} text="New Payment" className="my-10px" />

      {isDataLoaded && (
        <div className={styles.container}>
          {payments.map((payment) => (
            <div key={payment._id} className={styles.row}>
              <div className="is-flex align-items-center">
                <div>
                  <p className="admin-table-row-title">{payment.date}</p>
                  <p>
                    Client Name: <strong>{payment.client.name}</strong>
                  </p>
                  <p>
                    Client Address: <strong>{payment.client.address}</strong>
                  </p>
                  <p>
                    Total: <strong>{_.sumBy(payment.items, (x) => x.count * x.price)}€</strong>
                  </p>
                  <p className="admin-text-sm">
                    id: <strong>{payment._id}</strong>
                  </p>
                </div>
              </div>
              <div className="is-flex align-items-center mt-10px">
                <EditLink to={`/admin/users/${userId}/payments/${payment._id}/edit`} />
                <DeleteButton onClick={openDeleteConfirmation(payment._id)} className="ml-10px" />
              </div>
            </div>
          ))}
        </div>
      )}
    </AdminPageContainer>
  );
};

AdminPaymentsPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types,react/no-unused-prop-types
  history: PropTypes.object.isRequired,
};

export default AdminPaymentsPage;
