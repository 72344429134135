import React from 'react';
import PropTypes from 'prop-types';

import { BackLink } from './Buttons';

const AdminPageTitle = ({ to, title }) => {
  return (
    <div className="is-flex align-items-center">
      <BackLink to={to || '/admin'} />
      <p className="admin-page-title ml-20px">{title}</p>
    </div>
  );
};

AdminPageTitle.defaultProps = {
  to: '/admin',
};

AdminPageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
};

export default AdminPageTitle;
