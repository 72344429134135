import React from 'react';
import PropTypes from 'prop-types';

import AdminHeader from './AdminHeader';

import styles from './AdminPageContainer.module.scss';

const AdminPageContainer = ({ children }) => {
  return (
    <div className={styles.container}>
      <AdminHeader />
      {children}
    </div>
  );
};

AdminPageContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AdminPageContainer;
