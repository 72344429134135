import React from 'react';

import ProfessionBlock from './programs-screen/ProfessionBlock';

import styles from './ProgramsScreen.module.scss';
import { professionTypes } from '../../utils/utils';

const ProgramsScreen = () => {
  return (
    <div className={styles.wrapper} id="programs-container-id">
      <div className={styles.container}>
        <div className={styles.content}>
          <p className={styles.title}>studying programs</p>
          <p>
            The duration of the programs depends on your skills. Students can complete it for 12 months on average. We
            can help you to prepare a CV and portfolio with real projects which can help you to get an offer. We are
            guarantee that you will find a job when you complete our training program.
          </p>
        </div>
        <div className={styles.professionsContainer}>
          <ProfessionBlock
            duration={12}
            type={professionTypes.jsFrontend}
            items={[
              'Understanding the latest ECMAScript standards',
              'Build interactive websites with React.js',
              'Learn architecture of frontend applications',
            ]}
            title="Javascript frontend developer"
          />
          <ProfessionBlock
            duration={12}
            type={professionTypes.jsBackend}
            items={[
              'Understanding the latest ECMAScript standards',
              'Build scalable applications with Node.js',
              'Learn architecture of server applications',
            ]}
            title="Javascript backend developer"
          />
        </div>
      </div>
    </div>
  );
};

export default ProgramsScreen;
