import React from 'react';

import { ReactComponent as AboutIcon1 } from '../../icons/about-1.svg';
import { ReactComponent as AboutIcon2 } from '../../icons/about-2.svg';
import { ReactComponent as AboutIcon3 } from '../../icons/about-3.svg';
import { ReactComponent as AboutIcon4 } from '../../icons/about-4.svg';
import AboutScreenInfoBlock from './about-screen/AboutScreenInfoBlock';

import styles from './AboutScreen.module.scss';

const AboutScreen = () => {
  return (
    <div className={styles.wrapper} id="about-screen-container-id">
      <div className={styles.container}>
        <div className={styles.body}>
          <div className={styles.screenTitle}>About Us</div>
          <div className={styles.bodyText}>
            <p>
              Reinforcement provides a well-designed authoring courses for IT-specialists: both beginners and
              experienced developers.
            </p>
            <p>
              Our mission is to make the learning process easier and understandable, realizing the relationship between
              the expectations of employers and the competencies of specialists.
            </p>
            <p>
              Training programs provide deep knowledge. A huge amount of different practical tasks for different levels.
            </p>
          </div>
        </div>
        <div className={styles.footerContainer}>
          <div className={styles.footerBody}>
            <AboutScreenInfoBlock icon={<AboutIcon1 className={styles.icon} />} title="Practice training">
              We provide a huge number of practical exercises that helps you to prepare for the interview
            </AboutScreenInfoBlock>
            <AboutScreenInfoBlock icon={<AboutIcon2 className={styles.icon} />} title="Experienced mentor">
              During the training, you will be attached to one of our mentors, who will significantly accelerate the
              training process
            </AboutScreenInfoBlock>
            <div className={styles.flexRowBreak} />
            <AboutScreenInfoBlock icon={<AboutIcon3 className={styles.icon} />} title="Modern technologies">
              We use all modern technologies and trends in our training process and continuously moderate them to be in
              the first line of progress
            </AboutScreenInfoBlock>
            <AboutScreenInfoBlock icon={<AboutIcon4 className={styles.icon} />} title="Unique courses">
              All programs are developed by experienced professionals with extensive experience in the web development
            </AboutScreenInfoBlock>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutScreen;
