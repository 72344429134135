/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import classnames from 'classnames';

import styles from './ProfilePage.module.scss';
import Logo from '../components/common/logo/Logo';
import actions from '../actions';

import { ReactComponent as UserIcon } from '../icons/user.svg';
import { checkUserAuth } from '../utils/auth-utils';
import Loader from '../components/common/loader/Loader';
import CollapsableBlock from '../components/profile/CollapsableBlock';
import UserSettingsForm from '../components/profile/UserSettingsForm';
import ChangePasswordForm from '../components/profile/ChangePasswordForm';
import ProfileFooter from '../components/profile/ProfileFooter';
import LinkButton from '../components/common/link/LinkButton';
import WebinarBlock from '../components/profile/WebinarBlock';
import TimetableBlock from '../components/profile/TimetableBlock';
import PaymentsTable from '../components/profile/PaymentsTable';

const tabs = ['Settings', 'Payments'];

const defaultWebinar = {
  name: 'Coming soon',
  description: 'We are working to prepare the best webinar for you',
  date: 'Date is undefined',
  price: 0,
};

const ProfilePage = ({ history }) => {
  const dispatch = useDispatch();

  const logout = (e) => {
    e.preventDefault();
    dispatch(actions.authLogout());
    history.push('/login');
  };

  const { user: authUser, token } = useSelector((state) => state);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const changeTabLinkClick = (index) => (e) => {
    e.preventDefault();
    setSelectedTabIndex(index);
  };

  const [user, setUser] = useState({ name: '', email: '', phone: '' });
  // eslint-disable-next-line no-unused-vars
  const [payments, setPayments] = useState([]);
  const [webinar, setWebinar] = useState(defaultWebinar);
  const [timetable, setTimetable] = useState([]);
  const [status, setStatus] = useState({ isLoading: false, isError: false });

  useEffect(() => {
    setStatus({ isLoading: true, isError: false });
    axios
      .get('/api/v1/profile', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUser(response.data.user);
        setPayments(response.data.payments);

        if (response.data.webinar) {
          setWebinar(response.data.webinar);
        }

        if (response.data.timetable) {
          setTimetable(response.data.timetable);
        }

        setStatus({ isLoading: false, isError: false });
      })
      .catch((e) => {
        setStatus({ isLoading: false, isError: true });
        checkUserAuth(e, history);
      });
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Loader isVisible={status.isLoading} />
        <div className={styles.header}>
          <Logo />
          <a href="#" className={styles.headerLink} onClick={logout}>
            Logout
          </a>
        </div>
        <div className={styles.profileHeader}>
          <UserIcon />
          <span className={styles.userName}>{user.name}</span>
          {!!authUser.isAdmin && (
            <LinkButton title="Admin Panel" to="/admin" type="primary" isFixed width={150} height={35} />
          )}
        </div>
        <div className={styles.tabs}>
          {tabs.map((tabName, tabIndex) => {
            const tabClassNames = classnames(styles.tab, { [styles.activeTab]: selectedTabIndex === tabIndex });

            return (
              // eslint-disable-next-line react/no-array-index-key
              <a href="#" key={tabIndex} className={tabClassNames} onClick={changeTabLinkClick(tabIndex)}>
                {tabName}
              </a>
            );
          })}
        </div>
        <div className={styles.tabsContent}>
          {selectedTabIndex === 0 && (
            <div className={styles.userSettingsContainer}>
              <div className={styles.userSettingBlocks}>
                <CollapsableBlock title="Profile Settings">
                  <UserSettingsForm user={user} token={token} onUpdate={setUser} />
                </CollapsableBlock>
                <CollapsableBlock title="Password Settings">
                  <ChangePasswordForm token={token} />
                </CollapsableBlock>
              </div>
              <div className={styles.userSettingsRightBlocks}>
                <WebinarBlock webinar={webinar} />
                <TimetableBlock timetable={timetable} />
              </div>
            </div>
          )}
          {selectedTabIndex === 1 && (
            <div>
              <PaymentsTable payments={payments} />
            </div>
          )}
        </div>
      </div>
      <ProfileFooter />
    </div>
  );
};

ProfilePage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default ProfilePage;
