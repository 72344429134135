import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './Header.module.scss';

import { ReactComponent as MenuIcon } from '../../../icons/menu.svg';
import Logo from '../logo/Logo';
import { professionTypes } from '../../../utils/utils';

const Header = ({ type, openSidebar }) => {
  const iconClassName = classnames(styles.menuIcon, {
    [styles.frontendMenuIcon]: type === professionTypes.jsFrontend,
    [styles.backendMenuIcon]: type === professionTypes.jsBackend,
  });

  return (
    <div className={styles.container}>
      <Logo />
      <MenuIcon className={iconClassName} onClick={openSidebar} />
    </div>
  );
};

Header.defaultProps = {
  type: 'default',
};

Header.propTypes = {
  type: PropTypes.string,
  openSidebar: PropTypes.func.isRequired,
};

export default Header;
