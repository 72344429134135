import { useState, useEffect } from 'react';
import _ from 'lodash';
import update from 'immutability-helper';
import axios from 'axios';
import { useSelector } from 'react-redux';

const createStatus = (isLoading, isError, isSaving, canSave) => ({ isLoading, isError, isSaving, canSave });

const defaultPayment = {
  client: { name: '', address: '' },
  date: new Date(),
  items: [],
};

const checkPayment = (value) => {
  let result = { client: {}, items: {} };
  if (value.client.name.trim() === '') {
    result.client.name = 'Name cannot be empty';
  }

  if (value.client.address.trim() === '') {
    result.client.address = 'Address cannot be empty';
  }

  for (let i = 0; i < value.items.length; i += 1) {
    const item = value.items[i];

    const errorItem = {};

    if (item.count <= 0) {
      errorItem.count = 'Count should be greater than zero';
    }

    if (item.description.trim() === '') {
      errorItem.description = 'Description cannot be empty';
    }

    if (item.price <= 0) {
      errorItem.price = 'Price should be greater than zero';
    }

    if (_.keys(errorItem).length > 0) {
      result = update(result, { items: { [i]: { $set: errorItem } } });
    }
  }

  return result;
};

const hasErrors = (errors) => {
  return _.keys(errors.client).length > 0 || _.keys(errors.items).length > 0;
};

const useAdminEditPaymentPageState = (userId, paymentId, history) => {
  const { token } = useSelector((state) => state);
  const [status, setStatus] = useState({ isLoading: false, isError: false, isSaving: false, canSave: false });
  const [payment, setPayment] = useState(defaultPayment);
  const [errors, setErrors] = useState(checkPayment(defaultPayment));
  const [userName, setUserName] = useState('');
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const isEditMode = !_.isUndefined(paymentId);

  useEffect(() => {
    if (!isEditMode) {
      setStatus(createStatus(true, false, false, false));
      axios
        .get(`/api/v1/admin/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setUserName(response.data.userName);
          setStatus(createStatus(false, false, false, !hasErrors(errors)));
          setIsDataLoaded(true);
        })
        .catch(() => {
          setStatus(createStatus(false, true, false, false));
        });
    } else {
      setStatus(createStatus(true, false, false, false));
      axios
        .get(`/api/v1/admin/users/${userId}/payments/${paymentId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setUserName(response.data.userName);
          const loaded = response.data.payment;
          const items = loaded.items.map((x) => ({ ...x, total: x.count * x.price }));

          setPayment({ ...loaded, date: new Date(loaded.date), items, total: _.sumBy(items, (x) => x.total) });
          const newErrors = checkPayment(response.data.payment);
          setErrors(newErrors);
          setStatus(createStatus(false, false, false, !hasErrors(newErrors)));
          setIsDataLoaded(true);
        })
        .catch(() => {
          setStatus(createStatus(false, true, false, false));
        });
    }
  }, []);

  const updatePayment = (newPayment) => {
    const newErrors = checkPayment(newPayment);

    const total = _.sumBy(newPayment.items, (x) => x.total);

    setPayment(update(newPayment, { total: { $set: total } }));
    setErrors(newErrors);
    setStatus(update(status, { canSave: { $set: !hasErrors(newErrors) } }));
  };

  const changeDate = (value) => {
    setPayment(update(payment, { date: { $set: value } }));
  };

  const changeClientName = (e) => {
    updatePayment(update(payment, { client: { name: { $set: e.target.value } } }));
  };

  const changeClientAddress = (e) => {
    updatePayment(update(payment, { client: { address: { $set: e.target.value } } }));
  };

  const addItem = () => {
    updatePayment(update(payment, { items: { $push: [{ count: 0, description: '', price: 0, total: 0, rate: 0 }] } }));
  };

  const deleteItem = (index) => () => {
    updatePayment(update(payment, { items: { $splice: [[index, 1]] } }));
  };

  const changeItemCount = (index) => (e) => {
    const value = _.toNumber(e.target.value);

    const newValue = !_.isNaN(value) && _.isInteger(value) && value > 0 ? value : payment.items[index].count;
    const { price } = payment.items[index];

    updatePayment(
      update(payment, { items: { [index]: { count: { $set: newValue }, total: { $set: newValue * price } } } }),
    );
  };

  const changeItemDescription = (index) => (e) => {
    updatePayment(update(payment, { items: { [index]: { description: { $set: e.target.value } } } }));
  };

  const changeItemPrice = (index) => (e) => {
    const value = _.toNumber(e.target.value);

    const newValue = !_.isNaN(value) && _.isInteger(value) && value > 0 ? value : payment.items[index].price;
    const { count } = payment.items[index];

    updatePayment(
      update(payment, { items: { [index]: { price: { $set: newValue }, total: { $set: newValue * count } } } }),
    );
  };

  const changeItemRate = (index) => (e) => {
    const value = _.toNumber(e.target.value);

    const newValue = !_.isNaN(value) && _.isInteger(value) && value > 0 ? value : payment.items[index].rate;

    updatePayment(update(payment, { items: { [index]: { rate: { $set: newValue } } } }));
  };

  const save = () => {
    setStatus(createStatus(true, false, true, status.canSave));

    if (!isEditMode) {
      axios
        .post(
          `/api/v1/admin/users/${userId}/payments`,
          { payment },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(() => {
          setStatus(createStatus(false, false, false, status.canSave));
          history.push(`/admin/users/${userId}/payments`);
        })
        .catch(() => {
          setStatus(createStatus(false, true, false, status.canSave));
        });
    } else {
      axios
        .put(
          `/api/v1/admin/users/${userId}/payments/${paymentId}`,
          { payment },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(() => {
          setStatus(createStatus(false, false, false, status.canSave));
          history.push(`/admin/users/${userId}/payments`);
        })
        .catch(() => {
          setStatus(createStatus(false, true, false, status.canSave));
        });
    }
  };

  return {
    isDataLoaded,
    userName,
    status,
    payment,
    errors,
    actions: {
      changeDate,
      changeClientName,
      changeClientAddress,
      addItem,
      deleteItem,
      changeItemCount,
      changeItemDescription,
      changeItemPrice,
      changeItemRate,
      save,
    },
  };
};

export default useAdminEditPaymentPageState;
